export default {
  title: {
    es: 'Auditoría',
    en: 'Audit',
    pt: 'Auditoria'
  },
  event: {
    es: 'Evento',
    en: 'Event',
    pt: 'Evento'
  },
  details: {
    es: 'Detalles',
    en: 'Details',
    pt: 'Detalhes'
  },
  path: {
    es: 'Ruta',
    en: 'Path',
    pt: 'Caminho'
  },
  user: {
    es: 'Usuario',
    en: 'User',
    pt: 'Usuário'
  },
  answer: {
    es: 'Respuesta',
    en: 'Answer',
    pt: 'Respuesta'
  },
  activity: {
    es: 'Actividad',
    en: 'Activity',
    pt: 'Actividad'
  },
  answerDeleted: {
    title: {
      es: 'Respuesta eliminada',
      en: 'Deleted answer',
      pt: 'Resposta eliminada'
    },
    text: {
      es: 'Esta respuesta fue eliminada.',
      en: 'This answer was deleted.',
      pt: 'Esta resposta foi eliminada.'
    },
    textWithTimeElapsed: {
      es: 'Esta respuesta fue eliminada XXX.',
      en: 'This answer was deleted XXX.',
      pt: 'Esta resposta foi eliminada XXX.'
    }
  }, 
  searchPlaceholder: {
    es: 'Buscar por evento, usuario, etc.',
    en: 'Search by event, user, etc.',
    pt: 'Pesquisar por evento, usuário, etc.'
  },
  date: {
    es: 'Fecha',
    en: 'Date',
    pt: 'Data'
  },
  bot: {
    es: 'Bot',
    en: 'Bot',
    pt: 'Bot'
  },
  error: {
    title: {
      es: 'Oops..',
      en: 'Oops',
      pt: 'Oops'
    },
    text: {
      es: 'Ha habido un error al obtener la Auditoría',
      en: 'There was an error getting the Audit',
      pt: 'Houve um erro em obter a Auditoria'
    }
  },
  events: {
    intent: {
      es: 'intención',
      en: 'intent',
      pt: 'intenções'
    },
    login: {
      es: 'inicio sesión',
      en: 'login',
      pt: 'login'
    },
    create: {
      es: 'crear',
      en: 'create',
      pt: 'criar'
    },
    delete: {
      es: 'borrar',
      en: 'delete',
      pt: 'deletar'
    },
    update: {
      es: 'actualizar',
      en: 'update',
      pt: 'atualização'
    },
    start: {
      es: 'comenzar',
      en: 'start',
      pt: 'começar'
    },
    finish: {
      es: 'finalizar',
      en: 'finsh',
      pt: 'finalizar'
    },
    takeover: {
      es: 'derivación a agente',
      en: 'takeover',
      pt: 'encaminhamento para agente'
    }
  },
  criteria: {
    criterion: [
      {
        title: {
          es: 'Bot',
          en: 'Bot',
          pt: 'Bot'
        },
        id: 1
      },
      {
        title: {
          es: 'Usuario',
          en: 'User',
          pt: 'Usuário'
        },
        id: 2
      },
      {
        title: {
          es: 'Tags',
          en: 'Tags',
          pt: 'Tags'
        },
        id: 3
      },
      {
        title: {
          es: 'Fecha',
          en: 'Date',
          pt: 'Data'
        },
        id: 4
      },
    ],
    function: {
      1: [
        {
          text: 'Is',
          id: 1
        },
        {
          text: 'Is not',
          id: 2
        }
      ],
      2: [
        {
          text: 'Is',
          id: 1
        },
        {
          text: 'Is not',
          id: 2
        }
      ],
      3: [
        {
          text: 'Is',
          id: 1
        },
        {
          text: 'Is not',
          id: 2
        },
        {
          text: 'Contain',
          id: 3
        }
      ],

      4: [
        {
          text: 'Is',
          id: 1
        },
        {
          text: 'Is not',
          id: 2
        },
        {
          text: 'before',
          id: 3
        },
        {
          text: 'After',
          id: 4
        },
        {
          text: 'Is within',
          id: 5
        },
        {
          text: 'Is not within',
          id: 6
        },
      ],


    }
  }
}
