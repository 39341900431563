export default {
  title: {
    es: 'Informes de retroalimentación',
    en: 'Feedback reports',
    pt: 'Relatórios de feedback'
  },
  subtitle: {
    en: "Review and manage feedback to improve responses and user experience.",
    es: "Revisar y gestionar la retroalimentación para mejorar las respuestas y la experiencia del usuario.",
    pt: "Revisar e gerenciar feedbacks para melhorar as respostas e a experiência do usuário.",
  },
  pagination: {
    perPageText: {
      es: 'Elementos por página:',
      en: 'Items per page:',
      pt: 'Itens por página:',
    },
    ofText: {
      es: 'de',
      en: 'of',
      pt: 'de',
    },
    itemsText: {
      es: 'elementos',
      en: 'items',
      pt: 'itens',
    },
  },
  filters: {
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    accept: ''
  },
  table: {
    bot: {
      es: 'Nombre del bot',
      en: 'Bot name',
      pt: 'Nome do bot'
    },
    feedback: {
      es: 'Retroalimentación',
      en: 'Feedback',
      pt: 'Opinião'
    },
    inputUser: {
      es: 'Pregunta del usuario',
      en: 'User question',
      pt: 'Pergunta do usuário'
    },
    messageFeedback: {
      en: 'Feedback reason',
      es: 'Motivo de la respuesta',
      pt: 'Motivo do feedback'
    },
    messageShow: {
      es: 'Respuesta',
      en: 'Answer',
      pt: 'Resposta',
    },
    date: {
      en: 'Date',
      es: 'Fecha',
      pt: 'Data'
    },
    action: {
      en: 'Action',
      es: 'Acción',
      pt: 'Ação'
    }
  },
  tableV2: {
    trained: {
      en: "Trained",
      es: "Entrenado",
      pt: "Treinado"
    },
    userInput: {
      en: "User Input",
      es: "Mensaje del Usuario",
      pt: "Mensagem do usuário"
    },
    botName: {
      en: 'Bot Name',
      es: 'Nombre del Bot',
      pt: 'Nome do Bot'
    },
    answerName: {
      en: "Answer Name",
      es: 'Nombre de la Respuesta',
      pt: 'Nome da Resposta'
    },
    feedback: {
      es: 'Retroalimentación',
      en: 'Feedback',
      pt: 'Opinião'
    },
    feedbackReason: {
      en: 'Feedback Reason',
      es: 'Motivo de la Respuesta',
      pt: 'Motivo do feedback'
    },
    date: {
      en: 'Date',
      es: 'Fecha',
      pt: 'Data'
    }
  },
  tableBody: {
    like: {
      en: 'Positive',
      es: 'Positivo',
      pt: 'Positivo'
    },
    dislike: {
      en: 'Negative',
      es: 'Negativo',
      pt: 'Negativo'
    },
    editAnswer: {
      en: "Edit answer",
      es: "Editar respuesta",
      pt: "Editar resposta"
    },
    viewAnswer: {
      en: "View answer",
      es: "Ver respuesta",
      pt: "Ver resposta"
    },
    goToConversation: {
      en: "Go to conversation",
      es: "Ir a la conversación",
      pt: "Ir para a conversa"
    }
  }
}
