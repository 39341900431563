export default {
  title: {
    es: 'Calendarios',
    en: 'Schedules',
    pt: 'Calendários',
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome',
  },
  timeZone: {
    placeholder: {
      es: 'Filtrar por zona horaria',
      en: 'Filter by time zone',
      pt: 'Filtrar por fuso horário'
    },
    names:{
      es: 'Zona horaria',
      en: 'Timezone',
      pt: 'Fuso horário',
    },
  },
  search: {
    placeholder:{
      es:'Buscar por nombre o zona horaria',
      en:'Search by name or timezone',
      pt:'Pesquisar por nome ou fuso horário',
    }
  },
  select: {
    title: {
      es: 'Calendario',
      en: 'Calendar',
      pt: 'Calendário',
    },
    placeholder: {
      es: 'Seleccione un calendario',
      en: 'Select a calendar',
      pt: 'Selecione um calendário',
    },
    notFound: {
      es: 'Calendario no encontrado',
      en: 'Calendar not found',
      pt: 'Calendário não encontrado'
    },
  },
  rule: {
    es: 'Repetir cada',
    en: 'Repeat every',
    pt: 'Repita a cada',
    sunday: {
      es: 'Do',
      en: 'Su',
      pt: 'Do',
    },
    monday: {
      es: 'Lu',
      en: 'Mo',
      pt: 'Se'
    },
    tuesday: {
      es: 'Ma',
      en: 'Tu',
      pt: 'Te'
    },
    wednesday: {
      es: 'Mi',
      en: 'We',
      pt: 'Qu'
    },
    thursday: {
      es: 'Ju',
      en: 'Th',
      pt: 'Qu'
    },
    friday: {
      es: 'Vi',
      en: 'Fr',
      pt: 'Se'
    },
    saturday: {
      es: 'Sa',
      en: 'Sa',
      pt: 'Sa'
    },      
    to: {
      es: 'a',
      en: 'to',
      pt: 'a'
    },
    allDay: {
      es: 'Todo el día',
      en: 'All Day',
      pt: 'Todo o dia'
    }
  },
  text: {
    from: {
      es: 'Desde',
      en: 'From',
      pt: 'De'
    },
    to: {
      es: 'Hasta',
      en: 'To',
      pt: 'A'
    }
  },
  except: {
    es: 'Excepto (Opcional)',
    en: 'Except (Optional)',
    pt: 'Exceto (Opcional)'
  },
  days: {
    es: 'Día(s)',
    en: 'Day(s)',
    pt: 'Dia(s)'
  },
  date: {
    es: 'Fecha',
    en: 'Date',
    pt: 'Data'
  },
  exceptRule:{
    datapicker:{
      placeholder:{
        es: 'Seleccione fecha',
        en: 'Select date',
        pt: 'Selecione a data'
      }
    }
  },
  validationErrors: {
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre es requerido y debe contener menos de 256 caracteres.',
        en: 'The name is required and must contain less than 256 characters.',
        pt: 'O nome é necessário e deve conter menos de 256 caracteres.',
      },
    },
    duplicatedName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre esta duplicado ',
        en: 'The name is duplicated ',
        pt: 'O nome é duplicado ',
      },
    },
    noSelectTimeZone: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Debe seleccionar un uso horario ',
        en: 'You must select a Time Zone ',
        pt: 'Você deve selecionar um fuso horário ',
      },
    },
    emptyRules: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Debe agregar al menos una regla ',
        en: 'You must add at least one rule ',
        pt: 'Você deve adicionar pelo menos uma regra ',
      },
    },
    exceptionRules: {     
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      dateException: {
        es: 'Debe seleccionar fecha para la excepción. ',
        en: 'You must select a date for the exception. ',
        pt: 'Você deve selecionar uma data para a exceção. ',
      },
      timeException: {
        es: 'Debe seleccionar un rango de tiempo para la excepción. ',
        en: 'You must select a time range for the exception. ',
        pt: 'Você deve selecionar um intervalo de tempo para a exceção. ',
      },
    },
    rules:{
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      time: {
        es: 'Debe seleccionar un rango de tiempo para la regla. ',
        en: 'You must select a time range for the rule. ',
        pt: 'Você deve selecionar um intervalo de tempo para a regra. ',
      },
    }
  },
  add: {
    addRule: {
      es: 'Agregar regla',
      en: 'Add rule',
      pt: 'Adicionar regra',
    },
    addException: {
      es: 'Agregar excepción',
      en: 'Add exception',
      pt: 'Adicionar exceção',
    },
    popUpTitle: {
      es: 'Agregar Calendario',
      en: 'Add Schedule',
      pt: 'Adicionar Cronograma',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'El calendario fue creado correctamente.',
        en: 'The schedule was created succesfully.',
        pt: 'A cronograma foi criado com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar Calendario',
      en: 'Edit Schedule',
      pt: 'Editar Cronograma',
    },
    readOnlyTitle: {
      es: 'Ver Horario (solo lectura)',
      en: 'View Schedule (read-only)',
      pt: 'Ver Horário (somente leitura)',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'El calendario fue actualizado correctamente.',
        en: 'The schedule was updated succesfully.',
        pt: 'A cronograma foi atualizado com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Estas por borrar un calendario, esta acción es irreversible. ¿Estás seguro?',
        en: 'You are about to erase a schedule, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar um cronograma, esta ação é irreversível',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'El calendario fue eliminado correctamente.',
        en: 'The schedule was deleted succesfully.',
        pt: 'A cronograma foi deletada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  defaultValues:{
    button:{
      yes:{
        es: 'Si',
        en: 'Yes',
        pt: 'Sim',
      },
      no:{
        es: 'No',
        en: 'No',
        pt: 'Não',
      }
    
    },
    text:{
      es: 'Copiar los días, horarios y excepciones configurados por defecto en el bot.',
      en: 'Copy the days, times and exceptions configured by default in the bot.',
      pt: 'Copie os dias, horários e exceções configurados por padrão no bot.',
    }
  }
}