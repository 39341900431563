import { createStore } from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'

import moduleBotMaker from './botMaker/moduleBotMaker'
import moduleBots from './bots/moduleBots'
import moduleCredentials from './credentials/moduleCredentials'
import integrations from './integrations'
import ipRanges from './ipRanges'
import clusters from './clusters'
import queues from './queues'
import contact from './contact'
import companies from './companies'
import dialogs from './dialogs'
import answerComponent from './answerComponent'
import faunaWidget from './faunaWidget'
import feedback from './feedback'
import chartBuilder from './chartBuilder'
import chartBuilderGrouped from './chartBuilderGrouped'
import answerHistory from './answerHistory'
import conditionCatalog from './conditionCatalog'
import proactiveRule from './proactiveRule'
import variables from './variables'
import tags from './tags'
import templateBubbles from './templateBubbles'
import tagCategories from './tagCategories'
import permissions from './permissions'
import allChats from './allChats'
import marketplaceApplication from './marketplace-application'
import templates from './template'
import templatesInstalled from './template-installed'

export default createStore({
  mutations,
  state,
  actions,
  modules: {
    allChats,
    botMaker: moduleBotMaker,
    bots: moduleBots,
    credentials: moduleCredentials,
    integrations,
    ipRanges,
    clusters,
    contact,
    companies,
    queues,
    dialogs,
    answerComponent,
    faunaWidget,
    feedback,
    answerHistory,
    chartBuilder,
    conditionCatalog,
    proactiveRule,
    variables,
    tags,
    templateBubbles,
    tagCategories,
    chartBuilderGrouped,
    permissions,
    marketplaceApplication,
    templates,
    templatesInstalled,
  },
  strict: false
})
