/** @type {import('vue').ObjectPlugin} */
export default {
  install(Vue) {
    /**
     * @see https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url
     *
     * @param {string} sourcePathOrName
     * @returns {string}
     */
    Vue.config.globalProperties.$imageLoad = (sourcePathOrName) => {
      // Check to remove leading slash
      if (sourcePathOrName.startsWith('/')) {
        sourcePathOrName = sourcePathOrName.substring(1)
      }

      const imageUrlGlob = import.meta.glob('../assets/images/**/*', {
        eager: true,
        query: 'url',
      });

      return imageUrlGlob[`../assets/images/${sourcePathOrName}`]?.default;
    }
  }
}
