export default {
  title: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Tag',
  },
  id: {
    es: 'Id',
    en: 'Id',
    pt: 'Id',
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome',
  },
  nameDanger: {
    es: 'Introduzca sólo letras, espacios y caracter "_"',
    en: 'Enter only letters, spaces and character "_"',
    pt: 'Digite apenas letras, espaços e o caractere "_".',
  },
  category: {
    es: 'Categoría',
    en: 'Category',
    pt: 'Categoria'
  },
  color: {
    es: 'Color',
    en: 'Color',
    pt: 'Cor',
  },
  preview: {
    es: 'Vista Previa',
    en: 'Preview',
    pt: 'Vista Prévia',
  },
  validationErrors: {
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre del tag es requerido y debe contener menos de 256 caracteres.',
        en: 'The tag name is required and must contain less than 256 characters.',
        pt: 'O nome da tag é obrigatório e deve conter menos de 256 caracteres.',
      },
    },
    invalidColor: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El valor de la etiqueta es requerido y debe contener menos de 256 caracteres.',
        en: 'Variable label is required and must contain less than 256 characters.',
        pt: 'O valor da etiqueta é necessário e deve conter menos de 256 caracteres.',
      },
    },
    duplicatedName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El tag contiene un nombre duplicado. ',
        en: 'The tag contains a duplicate name. ',
        pt: 'A tag contém um nome duplicado. ',
      },
    }
  },
  delete: {
    popUpTitle: {
      es: 'Borrar Etiqueta',
      en: 'Delete Tag',
      pt: 'Excluir rótulo',
    },
  },
  read: {
    popUpTitle: {
      es: 'Ver Etiqueta',
      en: 'View Tag',
      pt: 'Exibir rótulo',
    },
  },
  add: {
    popUpTitle: {
      es: 'Agregar Etiqueta',
      en: 'Add Tag',
      pt: 'Adicionar rótulo',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    color: {
      es: 'Color',
      en: 'Color',
      pt: 'Cor',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    labelEmpty:{
      es: 'vacío',
      en: 'empty',
      pt: 'vazio',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La etiqueta fue creada correctamente.',
        en: 'The tag was created successfully.',
        pt: 'A etiqueta foi criada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar Etiqueta',
      en: 'Edit Tag',
      pt: 'Editar Etiqueta',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    addValue: {
      es: '+ Agregar valor',
      en: '+ Add value',
      pt: '+ Adicione valor'
    },
    value: {
      es: 'Valor',
      en: 'Value',
      pt: 'Valor',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La etiqueta fue actualizada correctamente.',
        en: 'The tag was updated successfully.',
        pt: 'A etiqueta foi atualizada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  readOnly: {
    popUpTitle: {
      es: 'Ver Etiqueta (solo lectura)',
      en: 'View Tag (read-only)',
      pt: 'Ver Tag (somente leitura)',
    }
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está a punto de borrar una etiqueta, esta acción es irreversible. ¿Estas seguro? ',
        en: 'You are about to erase a tag, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar uma tag, esta ação é irreversível. Tem certeza?',
      },
    },
    dialogAccept: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está etiqueta esta siendo usada, no es posible eliminar. ',
        en: 'This tag is being used, it is not possible to delete it.',
        pt: 'Esta tag está sendo usada, não é possível excluí-la.',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La etiqueta se eliminó correctamente.',
        en: 'The tag was successfully removed.',
        pt: 'A etiqueta foi removida com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
}