export default {
  title: {
    es: 'Seguridad',
    en: 'Security',
    pt: 'Segurança'
  },
  ipRanges: {
    title: {
      es: 'Rangos de IP',
      en: 'IP ranges',
      pt: 'IP ranges'
    },
    searchPlaceholder: {
      es: 'Buscar por rango',
      en: 'Search by range',
      pt: 'Pesquisar por intervalo'
    },
    add: {
      popUpTitle: {
        es: 'Agregar rango',
        en: 'Add range',
        pt: 'Adicionar range'
      },
      range: {
        es: 'Rango',
        en: 'Range',
        pt: 'Range'
      },
      cancelText: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
      saveText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El rango fue creado correctamente.',
          en: 'The range was created.',
          pt: 'O range foi criado.'
        }
      },
      error: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.'
        }
      }
    },
    validationErrors: {
      emptyRange: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'El rango es requerido.',
          en: 'Range is required.',
          pt: 'O range é necessário.'
        }
      },
      notValid: {
        title: {
          es: 'Inválido',
          en: 'Invalid',
          pt: 'Inválido'
        },
        text: {
          es: 'El rango ingresado no es válido, por favor verifíquelo.',
          en: 'Range value is not valid, please check it.',
          pt: 'O valor do range não é válido, por favor verifique.'
        }
      }
    },
    edit: {
      popUpTitle: {
        es: 'Editar rango',
        en: 'Edit range',
        pt: 'Editar o range'
      },
      range: {
        es: 'Rango',
        en: 'Range',
        pt: 'Range'
      },
      cancelText: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
      saveText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El rango fue actualizado correctamente.',
          en: 'The range was updated.',
          pt: 'O range foi atualizado.'
        }
      },
      error: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.'
        }
      }
    },
    remove: {
      dialog: {
        title: {
          es: 'Advertencia!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        text: {
          es:
            'Estas por borrar un rando de IP, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase an IP range, this action is irreversible. Are you sure?',
          pt: 'Você está prestes a apagar um range IP, esta ação é irreversível'
        }
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El rango fue eliminado correctamente.',
          en: 'The range was deleted.',
          pt: 'O range foi deletado.'
        }
      },
      error: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.'
        }
      }
    },
    table: {
      range: {
        es: 'Rango',
        en: 'Range',
        pt: 'Range'
      },
      _createdAt: {
        es: 'Creación',
        en: 'Created',
        pt: 'Criado'
      },
      _updatedAt: {
        es: 'Actualizado',
        en: 'Updated',
        pt: 'Atualizado'
      }
    },
    slider: {
      label: {
        es: 'Activar filtro de IP',
        en: 'Enable IP filtering',
        pt: 'Habilite a filtragem IP'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Configuración guardada correctamente.',
          en: 'Setting saved sucessfully.',
          pt: 'Configuração salva com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.'
        }
      }
    }
  }
}
