// MAIN COLORS - VUESAX THEME COLORS
export const themeColors = {
  primary: '#0096B6', // linear-gradient(rgb(255, 0, 61), rgb(255, 94, 0))
  success: '#128724',
  danger: '#B02210',
  warning: '#DFAD1C',
  dark: '#3B3B3B',
  'warning-dark': '#141414',
}

// CONFIGS
export const themeConfig = {
  theme: 'semi-dark'
}
