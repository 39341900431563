export default {
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está a punto de borrar una regla, esta acción es irreversible. ¿Estas seguro?',
        en: 'You are about to delete a rule, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a excluir uma regra, esta ação é irreversível. Tem certeza?',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La regla se eliminó correctamente.',
        en: 'The rule was successfully deleted.',
        pt: 'A regra foi excluída com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  table: {
    header: {
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome',
      },
      url: {
        es: 'URL',
        en: 'URL',
        pt: 'URL',
      },
    },
  },
  main: {
      tittle: {
        es: 'Reglas Proactivas',
        en: 'Proactive Rules',
        pt: 'Regras Proativas',
  },
  addButton: {
    es: 'Crear regla proactiva',
    en: 'Create proactive rule',
    pt: 'Criar regra proativa',
  },
},
search: {
  placeholder:{
    es:'Buscar por nombre',
    en:'Search by name',
    pt:'Buscar por nome',
  }
},
  popup: {
    aggregator: {
      sum: {
        es: 'Suma',
        en: 'Sum',
        pt: 'Soma',
      },
      product: {
        es: 'Producto',
        en: 'Product',
        pt: 'Produtos',
      },
      average: {
        es: 'Promedio',
        en: 'Average',
        pt: 'Média',
      },
      max: {
        es: 'Máx',
        en: 'Max',
        pt: 'Máx',
      },
      min: {
        es: 'Mín',
        en: 'Min',
        pt: 'Mín',
      },
      count: {
        es: 'Contar',
        en: 'Count',
        pt: 'Contar',
      },
      median: {
        es: 'Mediana',
        en: 'Median',
        pt: 'Mediana',
      }
    },
    proactiveMessage: {
      title: {
        es: 'Mensaje proactivo',
        en: 'Proactive message',
        pt: 'Mensagem proativa',
      }
    },
    validationErrors: {
      uniqueName: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Ingrese un nombre único para la regla, este nombre ya existe.',
          en: 'Enter a unique name for the rule, this name already exists.',
          pt: 'Insira um nome exclusivo para a regra, esse nome já existe.',
        },
      },
    
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Introduzca menos de 255 caracteres para el nombre de la regla.',
        en: 'Enter less than 255 characters for the rule name.',
        pt: 'Insira menos de 255 caracteres para o nome da regra.',
      },
    },
    emptyName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Introduzca un nombre para la regla.',
        en: 'Enter a name for the rule.',
        pt: 'Digite um nome para a regra.',
      },
    },
    duplicatedName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La regla contiene un nombre duplicado. ',
        en: 'The rule contains a duplicate name. ',
        pt: 'A regra contém um nome duplicado. ',
      },
    },
    tagEmpty: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Seleccione una etiqueta. ',
        en: 'Select a tag. ',
        pt: 'Selecione uma etiqueta. ',
      },
      },
      queryToListEmpty: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Complete la sección "Consultar una lista" o vacíela. ',
          en: 'Complete the "Query a list" section or empty it. ',
          pt: 'Complete a seção "Consultar uma lista" ou esvazie-a. ',
        },
      },
     conditionEmpty: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Complete la sección "Condiciones" o vacíela. ',
          en: 'Complete the "Conditions" section or empty it. ',
          pt: 'Complete a seção "Condições" ou esvazie-a. ',
        },
      },
      
  },
  editTittle: {
    es: 'Editar regla proactiva',
    en: 'Edit proactive rule',
    pt: 'Regras Proativas',
  },
  addTittle: {
    es: 'Crear regla proactiva',
    en: 'Create proactive rule',
    pt: 'Criar regra proativa',
    },
  readOnlyTitle: {
      es: 'Ver Regla Proactiva (solo lectura)',
      en: 'View Proactive Rule (read-only)',
      pt: 'Ver Regra Proativa (somente leitura)',
    },
  cancelText: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  saveText: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar',
  },
    collapse: {
      definition: {
        es: 'Establezca una lógica para activar un chat proactivo',
        en: 'Set a logic to trigger a proactive chat',
        pt: 'Defina uma lógica para acionar um chat proativo',
    },
    tittle:{
      condition:{
        es: 'Condiciones',
        en: 'Conditions',
        pt: 'Condições',
      },
      queryAList:{
        es: 'Consulta a lista',
        en: 'Query a list',
        pt: 'Consultar uma lista'
      },
    },
  },
  queryToList: {
    listVar: {
      label:{
        es: 'Variable de tipo de lista',
        en: 'List type variable',
        pt: 'Variável de tipo de lista'
      },
      placeholder: {
        es: 'Seleccione variable',
        en: 'Select variable',
        pt: 'Selecionar variável'
      },
    },
    operationResult: {
      title: {
        es: 'Resultados de la operación',
        en: 'Operation results',
        pt: 'Resultados da operação'
      },
      funcOp: {
        label: {
          es: 'Función',
          en: 'Function',
          pt: 'Função'
        },
        placeholder: {
          es: 'Seleccione función',
          en: 'Select function',
          pt: 'Selecione a função'
        }
      },
      fieldKey: {
        label: {
          es: 'Clave de campo',
          en: 'Field key',
          pt: 'Chave de campo'
        },
        placeholder: {
          es: 'Escribe o pegue una clave de campo',
          en: 'Write or paste a field key',
          pt: 'Escreva ou cole uma chave de campo'
        }
      },
      variable: {
        label: {
          es: 'Guardar resultado en una variable numérica',
          en: 'Save result in a number variable',
          pt: 'Salvar resultado em uma variável numérica'
        },
        placeholder: {
          es: 'Seleccione una variable',
          en: 'Select variable',
          pt: 'Selecionar variável'
        }
      }
    }
  },
  successEdit: {
    title: {
      es: 'Éxito',
      en: 'Success',
      pt: 'Sucesso',
    },
    text: {
      es: 'La regla fue actualizada correctamente.',
      en: 'The rule was successfully updated.',
      pt: 'A regra foi atualizada com sucesso.',
    },
  },
  successCreate: {
    title: {
      es: 'Éxito',
      en: 'Success',
      pt: 'Sucesso',
    },
    text: {
      es: 'La regla fue creada correctamente.',
      en: 'The rule was created successfully.',
      pt: 'A regra foi criada com sucesso.',
    },
  },
  error: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!',
    },
    text: {
      es: 'Parece que ocurrió un error.',
      en: 'Looks like something went wrong.',
      pt: 'Parece que algo deu errado.',
    },
  },
  name:{
    value:{
      es: 'Nombre de regla',
      en: 'Rule name',
      pt: 'Nome da regra',
    },
    placeholder:{
      es: 'Agregue un nombre descriptivo para la regla',
      en: 'Add a descriptive name for the rule',
      pt: 'Adicione um nome descritivo para a regra',
    }
  },
  url:{
    value:{
      es: 'URL',
      en: 'URL',
      pt: 'URL',
    },
    placeholder:{
      es: 'Puede agregar la url completa o una parte',
      en: 'You can add the full url or a part',
      pt: 'Você pode adicionar o URL completo ou uma parte',
    }
  },
  trigger:{
    value:{
      es: 'Triggers',
      en: 'Triggers',
      pt: 'Triggers',
    },
  },
  tag:{
      es: 'Etiqueta de mensaje',
      en: 'Message tag',
      pt: 'Etiqueta de mensagem',
    },
  answer: {
      es: 'Respuesta',
      en: 'Answer',
      pt: 'Respuesta'
    },
  rule: {
      es: 'Regla',
      en: 'Rule',
      pt: 'Regra'
    },
}
}