export default {
  title: {
    es: 'Variables',
    en: 'Variables',
    pt: 'Variáveis',
  },
  key: {
    es: 'Clave',
    en: 'Key',
    pt: 'Chave',
  },
  label: {
    es: 'Etiqueta',
    en: 'Label',
    pt: 'Etiqueta',
  },
  source: {
    en: 'Source',
    es: 'Fuente',
    pt: 'Fonte'
  },
  fieldType: {
    es: 'Tipo de campo',
    en: 'Field type',
    pt: 'Tipo de campo',
    options: {
      string: {
        es: 'Texto',
        en: 'Text',
        pt: 'Texto',
      },
      date: {
        es: 'Fecha',
        en: 'Date',
        pt: 'Data'
      },
      number: {
        es: 'Número',
        en: 'Number',
        pt: 'Número'
      },
      list: {
        es: 'Lista',
        en: 'List',
        pt: 'Lista'
      },
      secret: {
        es: 'Secreto',
        en: 'Secret',
        pt: 'Secret'
      }
    }
  },
  dataType: {
    es: 'Tipo de dato',
    en: 'Data type',
    pt: 'Tipo de dado',
    options: {
      normal: {
        es: 'Normal',
        en: 'Normal',
        pt: 'Normal'
      },
      sensitive: {
        es: 'Sensible',
        en: 'Sensitive',
        pt: 'Confidencial'
      },
      metadata: {
        es: 'Metadata',
        en: 'Metadata',
        pt: 'Metadata'
      },
      proactive: {
        es: 'Proactivo',
        en: 'Proactive',
        pt: 'Proativo'
      },
      secret: {
        es: 'Secreto',
        en: 'Secret',
        pt: 'Secret'
      }
    }
  },
  validationErrors: {
    invalidKey: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La clave de la variable es requerida y debe contener menos de 256 caracteres.',
        en: 'Variable key is required and must contain less than 256 characters.',
        pt: 'Chave da variável é necessário e deve conter menos de 256 caracteres.',
      },
    },
    formatKey: {
      text:{
        es: 'La clave debe contener letras, o una combinación junto con guión bajo (_) o simbolo de moneda ($), sin espacios en blanco.',
        en: 'The key must contain letters, or a combination together with an underscore (_) or currency sign ($), with no blank spaces.',
        pt: 'A chave deve conter letras ou uma combinação com um sublinhado (_) ou sinal de moeda ($), sem espaços em branco.',
    }
  },
    invalidLabel: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El valor de la etiqueta es requerido y debe contener menos de 256 caracteres.',
        en: 'Variable label is required and must contain less than 256 characters.',
        pt: 'O valor da etiqueta é necessário e deve conter menos de 256 caracteres.',
      },
    },
    invalidFieldType: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El valor del tipo de campo es requerido.',
        en: 'Variable field type is required.',
        pt: 'O valor da tipo de campo é necessário.',
      },
    },
    invalidDataType: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El valor del tipo de dato es requerido.',
        en: 'Variable data type is required.',
        pt: 'O valor da tipo de dado é necessário.',
      },
    },
    duplicatedKey: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La variable contiene clave duplicada ',
        en: 'Variable has duplicate key ',
        pt: 'Variável tem chave duplicada ',
      },
    }
  },
  add: {
    service: {
      es: 'Servicio',
      en: 'Service',
      pt: 'Serviço'
    },
    popUpTitle: {
      es: 'Agregar Variable',
      en: 'Add Variable',
      pt: 'Adicionar Variável',
    },
    key: {
      es: 'Clave',
      en: 'Key',
      pt: 'Chave',
    },
    label: {
      es: 'Etiqueta',
      en: 'Label',
      pt: 'Etiqueta',
    },
    fieldType: {
      es: 'Tipo de campo',
      en: 'Field type',
      pt: 'Tipo de campo',
    },
    calculated: {
      es: 'Variable calculada',
      en: 'Variable calculated',
      pt: 'Variable calculada',
    },
    dataType: {
      es: 'Tipo de dato',
      en: 'Data type',
      pt: 'Tipo de dado',
    },
    defaultValue: {
      es: 'Valor por defecto',
      en: 'Default value',
      pt: 'Valor padrão',
    },
    defaultTimeExpiration: {
      es: 'Caducidad de tiempo predeterminada',
      en: 'Default time expiration',
      pt: 'Expiração de tempo padrão',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La variable fue creada correctamente.',
        en: 'The variable was created.',
        pt: 'A variável foi criada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  delete: {
    popUpTitle: {
      es: 'Borrar Variable',
      en: 'Delete Variable',
      pt: 'Excluir variável',
    },
  },
  read: {
    popUpTitle: {
      es: 'Ver variable',
      en: 'View variable',
      pt: 'Exibir variável',
    },
  },
  edit: {
    preview: {
        es: 'Ver variable (solo lectura)',
        en: 'View variable (read-only)',
        pt: 'Exibir variável (somente leitura)',
    },
    popUpTitle: {
      es: 'Editar Variable',
      en: 'Edit Variable',
      pt: 'Editar Variável',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    addValue: {
      es: '+ Agregar valor',
      en: '+ Add value',
      pt: '+ Adicione valor'
    },
    value: {
      es: 'Valor',
      en: 'Value',
      pt: 'Valor',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La variable fue actualizada correctamente.',
        en: 'The variable was updated.',
        pt: 'A variável foi atualizada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Estas por borrar una variable, esta acción es irreversible. ¿Estás seguro?',
        en: 'You are about to erase a variable, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar uma variável, esta ação é irreversível',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La variable fue eliminada correctamente.',
        en: 'The variable was deleted.',
        pt: 'A variável foi deletada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
}