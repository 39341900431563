export default {
  title: {
    es: 'Integraciones',
    en: 'Integrations',
    pt: 'Integrações',
  },
  validationErrors: {
    name: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre de la integración es obligatorio.',
        en: 'The name of the integration is mandatory.',
        pt: 'O nome da integração é obrigatório.',
      },
    },
    url: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La URL ingresada no es válida.',
        en: 'The URL is not valid.',
        pt: 'O URL não é válido.',
      },
    },
    headers: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      empty: {
        es: 'Los encabezados no pueden estar vacios.',
        en: 'Headers cannot be empty.',
        pt: 'Os cabeçalhos não podem ficar vazios.',
      },
    },
    https: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El protocolo debe ser https.',
        en: 'The protocol must be https.',
        pt: 'O protocolo deve ser https.',
      },
    },
    variables: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'No deben haber variables vacias.',
        en: 'There should be no empty variables.',
        pt: 'Não deve haver variáveis ​​vazias.',
      },
    },
    formData: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'No debe haber datos de formulario vacios.',
        en: 'There should be no empty form data.',
        pt: 'Não deve haver dados de formulário vazios.',
      },
    },
    errorHandler: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Es obligatorio seleccionar una respuesta para el manejo de errores.',
        en: 'It is mandatory to select an answer for error handling.',
        pt: 'É obrigatório selecionar uma resposta para manipulação de erros.',
      },
    }
  },
  search: {
    es: 'Buscar una integración específica',
    en: 'Search for a specific integration',
    pt: 'Buscar uma integração específica',
  },
  list: {
    title: {
      es: 'Integraciones',
      en: 'Integrations',
      pt: 'Integrações',
    },
    url: {
      es: 'URL',
      en: 'URL',
      pt: 'URL',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    headers: {
      title: {
        es: 'Encabezado',
        en: 'Headers',
        pt: 'Cabeçalho',
      },
      header: {
        es: 'Encabezado',
        en: 'Headers',
        pt: 'Cabeçalho',
      },
      value: {
        es: 'Value',
        en: 'Value',
        pt: 'Value',
      },
      source: {
        en: 'Source',
        es: 'Fuente',
        pt: 'Fonte'
      },
      empty: {
        es: 'No hay encabezados disponibles',
        en: 'No headers available',
        pt: 'Nenhum cabeçalho disponível',
      },
    
    },
    notBeEdited: {
      en: 'Can not be edited or deleted',
      es: 'No se puede editar ni borrar',
      pt: 'Não pode ser editado ou excluído',
    },
    galileo_api: {
      es: 'Programa Galileo Api',
      en: 'Galileo Program Api',
      pt: 'Galileo Program Api'
    },
    welcome: {
      es: 'Bienvenido',
      en: 'Welcome',
      pt: 'Bem-vindo',
    },
    manualEntry: {
      es: 'Entrada manual',
      en: 'Manual Entry',
      pt: 'Entrada manual',
    },
    variables: {
      empty: {
        es: 'No hay variables',
        en: 'No variables available',
        pt: 'Não há variables disponíveis',
      }
    },
    dataForm: {
      empty: {
        es: 'No hay datos formulario',
        en: 'No data form available',
        pt: 'Não há dados de formulário',
      }
    }
  },
  add: {
    button: {
      es: 'Crear Integración',
      en: 'Create Integration',
      pt: 'Criar Integração',
    },
    popUpTitle: {
      es: 'Agregar Integración',
      en: 'Add Integration',
      pt: 'Adicionar integração',
    },
    url: {
      es: 'URL',
      en: 'URL',
      pt: 'URL',
    },
    documentationUrl: {
      en: 'Documentation URL',
      es: 'URL de la documentación',
      pt: 'URL da documentação',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    headers: {
      method: {
        es: 'Método',
        en: 'Method',
        pt: 'Método',
      },
      title: {
        es: 'Encabezados',
        en: 'Headers',
        pt: 'Cabeçalhos',
      },
      header: {
        es: 'Palabra clave',
        en: 'Key',
        pt: 'Palavra chave',
      },
      value: {
        es: 'Valor',
        en: 'Value',
        pt: 'Valor',
      },
      empty: {
        es: 'No hay encabezados disponibles',
        en: 'No headers available',
        pt: 'Nenhum cabeçalho disponível',
      },
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue creada correctamente.',
        en: 'The integration was created.',
        pt: 'A integração foi criada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  readOnly: {
    popUpTitle: {
      es: 'Ver Integración (solo lectura)',
      en: 'View Integration (read-only)',
      pt: 'Ver Integração (somente leitura)',
    }
  },
  read: {
    popUpTitle: {
      es: 'Ver Integración',
      en: 'View Integration',
      pt: 'Ver Integração',
    }
  },
  edit: {
    popUpTitle: {
      es: 'Editar Integración',
      en: 'Edit Integration',
      pt: 'Editar Integração',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue actualizada correctamente.',
        en: 'The integration was updated.',
        pt: 'A integração foi atualizada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  delete: {
    popUpTitle: {
      es: 'Eliminar Integración',
      en: 'Delete Integration',
      pt: 'Apagar Integração',
    },
  },
  documentation: {
    galileo_api: {
      es: 'Documentación Galileo',
      en: 'Galileo Documentation',
      pt: 'Documentação do Galileo',
    },
    welcome: {
      es: 'Documentación de bienvenida',
      en: 'Welcome Documentation',
      pt: 'Documentação de boas-vindas',
    }
  },
  remove: {
    dialog: {
      title: {
        es: 'Confirmación',
        en: 'Confirmation',
        pt: 'Confirmação',
      },
      text: {
        es: '¿Estás seguro de querer eliminar ésta integración?',
        en: 'Are you sure you want to delete the integration?',
        pt: 'Tem certeza que quer apagar a integração?',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue eliminada correctamente.',
        en: 'The integration was deleted.',
        pt: 'A integração foi deletada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
    webhookError: {
      title: {
        es: 'No puede ser eliminado!',
        en: 'Cant be deleted!',
        pt: 'Não pode ser apagado!',
      },
      text: {
        es: 'Este apiCall esta asociado a un webhook.',
        en: 'This apiCall is associated to a webhook.',
        pt: 'Esta apiCall está associada a um webhook.',
      },
    },
  },
  baseUrl: {
    es: 'URL Base',
    en: 'Base URL',
    pt: 'URL Base',
  },
  payload: {
    title: {
      es: 'Payload',
      en: 'Payload',
      pt: 'Payload',
    },
    key:{
      errorMessage:{
        es: 'La palabra clave no cumple con los estándares',
        en: 'The key does not meet the standards',
        pt: 'A palavra chave não atende aos padrões',
      }
    },
    addButton:{
      es: 'Agregar payload',
      en: 'Add payload',
      pt: 'Adicionar payload',
    },
    list:{
      empty: {
      es: 'No hay payload',
      en: 'No payload available',
      pt: 'Não há payload disponíveis',
    },
    variable:{
      label:{
        es: 'Variable',
        en: 'Variable',
        pt: 'Variable',
      },
      placeholder:{
        es: 'Selecciona una variable',
        en: 'Select a variable',
        pt: 'Selecione uma variável',
      },
    },
    key:{
      label:{
        es: 'Palabra clave',
        en: 'Key',
        pt: 'Palavra chave',
      },
      placeholder:{
        es: 'Escribe una palabra clave',
        en: 'Write a key',
        pt: 'Escreva uma palavra chave',
      },
    }
  },

  },
  variables: {
    title: {
      es: 'Actualizar Variables',
      en: 'Update Variables',
      pt: 'Atualizar Variáveis',
    },
    addButton:{
      es: 'Agregar variable',
      en: 'Add variable',
      pt: 'Adicionar variable',
    },
    list:{
      empty: {
      es: 'No hay variables',
      en: 'No variables available',
      pt: 'Não há variables disponíveis',
    },
    variable:{
      label:{
        es: 'Variable',
        en: 'Variable',
        pt: 'Variable',
      },
      placeholder:{
        es: 'Selecciona una variable',
        en: 'Select a variable',
        pt: 'Selecione uma variável',
      },
    },
    key:{
      label:{
        es: 'Palabra clave',
        en: 'Key',
        pt: 'Palavra chave',
      },
      placeholder:{
        es: 'Escribe una palabra clave',
        en: 'Write a key',
        pt: 'Escreva uma palavra chave',
      },
    }
  },

  },
  headers:{
    addButton:{
      es: 'Agregar encabezado',
      en: 'Add header',
      pt: 'Adicionar cabeçalho',
    },
    list:{
      value:{
        label:{
          es: 'Value',
          en: 'Valor',
          pt: 'Valor',
        },
        placeholder:{
          es: 'Escribe un valor',
          en: 'Write a value',
          pt: 'Escreva um valor',
        },
      },
      key:{
        label:{
          es: 'Palabra clave',
          en: 'Key',
          pt: 'Palavra chave',
        },
        placeholder:{
          es: 'Escribe una palabra clave',
          en: 'Write a key',
          pt: 'Escreva uma palavra chave',
        },
      }
    }
  },
  errorHandler: {
    title: {
      es: 'Manejo de Errores',
      en: 'Error Handler',
      pt: 'Manipulação de erros',
    },
    hasRetries:{
      label:{
        es: 'Tiene reintentos para esta API',
        en: 'Has retries to this API',
        pt: 'Tem tentativas para esta API',
      }
    },
    attempts:{
      label:{
        es: '¿Cuántos reintentos?',
        en: 'How many retries?',
        pt: 'Quantas tentativas?',
      }
    },
    errorAnswer:{
      label:{
        es: 'Respuesta de error',
        en: 'Error Answer',
        pt: 'Resposta do erro',
      }
    }
  },
  answerReponse:{
    placeholder:{
      es: 'Seleccione uno',
      en: 'Select one',
      pt: 'Selecione um',
    }
  },
  methods:{
    placeholder:{
      es: 'Seleccione uno',
      en: 'Select one',
      pt: 'Selecione um',
    },
  }
}