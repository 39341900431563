export default {
  apply: {
    es: 'Aplicar',
    en: 'Apply',
    pt: 'Aplicar',
  },
  reset: {
    es: 'Restaurar',
    en: 'Reset',
    pt: 'Restaurar',
  },
  save: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar',
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  continue: {
    es: 'Continuar',
    en: 'Continue',
    pt: 'Prosseguir',
  },
  download: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixe'
  },
  delete: {
    es: 'Eliminar',
    en: 'Delete',
    pt: 'Excluir'
},
  yes: {
    es: 'Sí',
    en: 'Yes',
    pt: 'Sim',
  },
  no: {
    es: 'No',
    en: 'No',
    pt: 'Não',
  },
  copyToClipboard: {
    label: {
      es: 'Copiar',
      en: 'Copy',
      pt: 'Copiar',
    },
    success: {
      es: 'Copiado al portapapeles!',
      en: 'Copied to clipboard!',
      pt: 'Copiado para a prancheta!',
    },
    error: {
      es: 'Ha ocurrido un error al copiar al portapapeles. Por favor, intente nuevamente.',
      en: 'An orror ocurred copying to clipboard. Please, try again.',
      pt: 'Ocorreu um erro ao copiar para a prancheta. Por favor, tente novamente.',
    },
  },
  cultureOptions: {
    title: {
      es: 'Idioma',
      en: 'Language',
      pt: 'Idioma',
    },
    spanish: {
      es: 'Español',
      en: 'Spanish',
      pt: 'Espanhol',
    },
    english: {
      es: 'Inglés',
      en: 'English',
      pt: 'Inglês',
    },
    portuguese: {
      es: 'Portugués',
      en: 'Portuguese',
      pt: 'Português'
    }
  },
  noDataText: {
    es: 'No se encontraron resultados',
    en: 'No results found',
    pt: 'Não foram encontrados resultados',
  },
  noResultsFound: {
    title: {
      en: "Sorry! No results found",
      es: "¡Lo sentimos! No se encontraron resultados",
      pt: "Desculpe! Não foram encontrados resultados"
    },
    description: {
      en: "Try adjusting the filters or search terms to find what you're looking for.",
      es: "Intenta ajustar los filtros o términos de búsqueda para encontrar lo que buscas.",
      pt: "Tente ajustar os filtros ou termos de pesquisa para encontrar o que procura."
    }
  },
  export: {
    es: 'Exportar',
    en: 'Export',
    pt: 'Exportar',
  },
  filters: {
    es: 'Filtros',
    en: 'Filters',
    pt: 'Filtros',
  },
  notApplied: {
    es: 'Algunos cambios aún no han sido aplicados.',
    en: 'Some changes have not yet been applied.',
    pt: 'Algumas mudanças ainda não foram aplicadas.',
  },
  time: {
    years: {
      es: 'Años',
      en: 'Years',
      pt: 'Anos',
    },
    weeks: {
      es: 'Semanas',
      en: 'Weeks',
      pt: 'Semanas',
    },
    days: {
      es: 'Días',
      en: 'Days',
      pt: 'Dias',
    },
    hours: {
      es: 'Horas',
      en: 'Hours',
      pt: 'Horas',
    },
    minutes: {
      es: 'Minutos',
      en: 'Minutes',
      pt: 'Minutos',
    },
  },
  search: {
    title:{
      es: 'Buscar',
      en: 'Search',
      pt: 'Pesquisar',
    },
    label: {
      es: 'Introduce la consulta',
      en: 'Enter Query',
      pt: 'Insira a consulta',
    },
    placeholder: {
      es: 'Introduce texto o filtra resultados',
      en: 'Enter text or filter results',
      pt: 'Digite texto ou filtre resultados',
    },
    placeholderNewSearch: {
      es: 'Introduzca texto o elija criterios para afinar su búsqueda...',
      en: 'Enter text or choose criteria to refine your search…',
      pt: 'Digite o texto ou escolha os critérios para refinar sua pesquisa...',
    },
    tooltip: {
      en: "Search freely or use criteria-based filters. Conditions are combined with 'AND', so all criteria must apply to the same bot.",
      es: "Busque libremente o utilice filtros basados en criterios. Las condiciones se combinan con 'Y', por lo que todos los criterios deben aplicarse al mismo bot.",
      pt: "Pesquise livremente ou use filtros baseados em critérios. As condições são combinadas com 'E', portanto, todos os critérios devem ser aplicados ao mesmo bot.",
    },
    tooltipWithoutFreelySearch: {
      en: "Use criteria-based filters. Conditions are combined with 'AND', so all criteria must apply to the same bot.",
      es: "Utilice filtros basados en criterios. Las condiciones se combinan con 'Y', por lo que todos los criterios deben aplicarse al mismo bot.",
      pt: "Use filtros baseados em critérios. As condições são combinadas com 'E', portanto, todos os critérios devem ser aplicados ao mesmo bot.",
    },
    loading: {
      es: 'Cargando...',
      en: 'Loading...',
      pt: 'Carregando...',
    },
    noResults: {
      en: "No results",
      es: "Sin resultados",
      pt: "Sem resultados",
    },
    date: {
      es: 'Fecha',
      en: 'Date',
      pt: 'Data'
    }
  },
}
