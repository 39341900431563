export default {
  pageTitle: {
    en: 'Answer Builder',
    es: 'Constructor de Respuestas',
    pt: 'Construtor de Respostas',
  },
  answers: {
    es: 'Lista de Respuestas',
    en: 'Answers List',
    pt: 'Lista de Respostas',
  },
  searchOldText: {
    es: 'Buscar respuestas',
    en: 'Search answers',
    pt: 'Pesquisa respostas',
  },
  search: {
    es: 'Buscar',
    en: 'Search',
    pt: 'Pesquisa',
  },
  noMoreAnswersToShow: {
    es: 'No hay más respuestas para mostrar',
    en: 'No more answers to show',
    pt: 'Não há mais respostas para mostrar',
  },
  noResultsFound: {
    title: {
      en: "Sorry! No results found",
      es: "¡Lo sentimos! No se encontraron resultados",
      pt: "Desculpe! Não foram encontrados resultados"
    },
    description: {
      en: "Try adjusting the filters or search terms to find what you're looking for.",
      es: "Intenta ajustar los filtros o términos de búsqueda para encontrar lo que buscas.",
      pt: "Tente ajustar os filtros ou termos de pesquisa para encontrar o que procura."
    }
  },
  sortAnswers: {
    es: 'Ordenar respuestas',
    en: 'Sort answers',
    pt: 'Ordenar respostas'
  },
  sortBy: {
    es: 'Ordenar por',
    en: 'Sort by',
    pt: 'Ordenar por'
  },
  sortingLabels: {
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome'
    },
    oldest: {
      es: 'Más antiguo',
      en: 'Oldest',
      pt: 'Mais antigo'
    },
    newest: {
      es: 'Más reciente',
      en: 'Newest',
      pt: 'Mais recente'
    },
    ascending: {
      es: 'Ascendente',
      en: 'Ascending',
      pt: 'Ascendente'
    },
    descending: {
      es: 'Descendente',
      en: 'Descending',
      pt: 'Descendente'
    }
  },
  answerStatus: {
    es: 'Inactivo',
    en: 'Inactive',
    pt: 'Inativo'
  },
  tags: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Etiquetas'
  },
  count: {
    es: 'Ocurrencias',
    en: 'Count',
    pt: 'Ocorrências'
  },
  usersOnAnswer: {
    es: 'Usuarios en la respuesta',
    en: 'Users on answer',
    pt: 'Usuários na resposta'
  },
  createAnswer: {
    es: 'Crear Respuesta',
    en: 'Create Answer',
    pt: 'Criar Resposta'
  },
  predefinedAnswer: {
    es: 'Respuestas predefinidas',
    en: 'Predefined Answers',
    pt: 'Respostas predefinidas'
  },
  all: {
    es: 'Todas',
    en: 'All',
    pt: 'Todas',
  },
}