export default {
  title: {
    es: 'Tienda',
    en: 'Marketplace',
    pt: 'Marketplace'
  },
  errors: {
    title: {
      en: 'Oops!',
      es: 'Oops!',
      pt: 'Oops!',
    },
    text: {
      es: 'Ha ocurrido un error al obtener las applicaciones de la tienda.',
      en: 'An error occurred while getting the applications from the store.',
      pt: 'Ocorreu um erro ao obter os aplicativos da loja.'
    },
  },
  galileoApiProgram: {
    title: {
      es: 'Configuración de la API del Programa Galileo',
      en: 'Galileo Program API Settings',
      pt: 'Configurações da API do programa Galileo',
    },
    requiredFields: {
      en: 'Required Fields',
      es: 'Campos obligatorios',
      pt: 'Campos obrigatórios',
    },
    form: {
      domain: {
        label: {
          en: 'Domain URL',
          es: 'URL del dominio',
          pt: 'URL do domínio',
        },
        tooltipText: {
          en: 'Enter domain only, max 100 characters.',
          es: 'Introduzca sólo el dominio, máx. 100 caracteres.',
          pt: 'Digite apenas o domínio, máximo de 100 caracteres.',
        },
        inputPlaceholder: {
          en: 'Enter domain only, e.g., example.gpsrv.',
          es: 'Introduzca sólo el dominio, por ejemplo, example.gpsrv.',
          pt: 'Digite apenas o domínio, por exemplo, example.gpsrv.',
        }
      },
      corename: {
        label: {
          en: 'Core name',
          es: 'Nombre principal',
          pt: 'Nome do núcleo',
        },
        tooltipText: {
          en: 'Enter core name only, max 25 characters.',
          es: 'Introduzca sólo el nombre del núcleo, máx. 25 caracteres.',
          pt: 'Digite apenas o nome do núcleo, com um máximo de 25 caracteres.',
        },
        inputPlaceholder: {
          en: 'Enter core name only, e.g., galileoapi',
          es: 'Introduzca sólo el nombre del núcleo, por ejemplo, galileoapi',
          pt: 'Digite apenas o nome do núcleo, por exemplo, galileoapi',
        }
      },
      apiLogin: {
        label: {
          en: 'apiLogin',
          es: 'apiLogin',
          pt: 'apiLogin',
        },
        tooltipText: {
          en: 'Max 50 characters.',
          es: 'Máximo 50 caracteres.',
          pt: 'Máximo de 50 caracteres.',
        },
        inputPlaceholder: {
          en: 'AbC123-9999',
          es: 'AbC123-9999',
          pt: 'AbC123-9999',
        }
      },
      apiTransKey: {
        label: {
          en: 'apiTransKey',
          es: 'apiTransKey',
          pt: 'apiTransKey',
        },
        tooltipText: {
          en: 'Max 15 characters',
          es: 'Máximo 15 caracteres',
          pt: 'Máximo de 15 caracteres',
        },
        inputPlaceholder: {
          en: '4sb62fh6w4h7w34g',
          es: '4sb62fh6w4h7w34g',
          pt: '4sb62fh6w4h7w34g',
        }
      },
      providerId: {
        label: {
          en: 'providerId',
          es: 'providerId',
          pt: 'providerId',
        },
        tooltipText: {
          en: 'Enter numbers only, maximum 10 digits.',
          es: 'Introduzca sólo números, máximo 10 dígitos.',
          pt: 'Digite apenas números, com no máximo 10 dígitos.',
        },
        inputPlaceholder: {
          en: '9999.',
          es: '9999',
          pt: '9999',
        }
      }
    },
    buttons: {
      doItLater: {
        en: 'Do it Later',
        es: 'Hágalo más tarde',
        pt: 'Faça isso depois',
      },
      saveAndInstall: {
        en: 'Save and Install',
        es: 'Guardar e instalar',
        pt: 'Salvar e instalar',
      },
      saveAndUpdate: {
        en: 'Save and Update',
        es: 'Guardar y actualizar',
        pt: 'Salvar e atualizar',
      }
    },
    goBackDialog: {
      title: {
        es: 'Atención',
        en: 'Warning',
        pt: 'Aviso'
      },
      text: {
        en: 'If you exit now, any completed fields will not be saved. Are you sure you want to leave?',
        es: 'Si sale ahora, los campos completados no se guardarán. ¿Está seguro de que desea salir?',
        pt: 'Se você sair agora, os campos preenchidos não serão salvos. Tem certeza de que deseja sair?',
      },
      acceptText: {
        en: 'Yes, leave',
        es: 'Sí, salir.',
        pt: 'Sim, sair',
      },
      cancelText: {
        en: 'Continue Editing',
        es: 'Seguir editando',
        pt: 'Continuar editando',
      }
    },
    validateApi: {
      errorTitle: {
        en: 'Oops!',
        es: 'Oops!',
        pt: 'Oops!',
      },
      errorsCodes: {
        APP_NOT_FOUND: {
          en: 'Application not found',
          es: 'Aplicación no encontrada',
          pt: 'Aplicativo não encontrado',
        },
        APP_NOT_CONFIGURED: {
          en: 'Application not configured correctly',
          es: 'Aplicación mal configurada',
          pt: 'Aplicativo não configurado corretamente',
        },
        DOMAIN_IS_INVALID: {
          en: 'The Domain URL was not found',
          es: 'No se ha encontrado la URL del dominio',
          pt: 'O URL do domínio não foi encontrado',
        },
        API_DONT_RESPOND: {
          en: `The Domain URL don't respond`,
          es: 'La URL del dominio no responde',
          pt: 'O URL do domínio não responde',
        },
        API_CREDENTIALS_ARE_INVALID: {
          en: 'The credentials entered are incorrect. Please check and try again.',
          es: 'Las credenciales introducidas son incorrectas. Por favor, compruébelo e inténtelo de nuevo.',
          pt: 'As credenciais inseridas estão incorretas. Verifique e tente novamente.',
        },
        SERVER_ERROR: {
          en: 'There was an error in validating the credentials.',
          es: 'Hubo un error en validar las credenciales',
          pt: 'Ocorreu um erro na validação das credenciais.',
        },
        API_PROVIDERID_IS_INVALID: {
          es: 'El providerId es inválido',
          en: 'The providerId is invalid',
          pt: 'O providerId é inválido',
        }
      }
    }
  },
}