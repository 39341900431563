<template>
  <transition name="dialog-t">
    <div
      v-show="isActive || active"
      ref="container"
      :class="[`vs-dialog-${color}`, 'vs-component con-vs-dialog']"
    >
      <div
        class="vs-dialog-dark"
        @click="($event) => (closeOnBlur ? close($event, true) : null)"
      />
      <div
        ref="dialog"
        class="vs-dialog"
      >
        <header style="color: rgba(var(--field-default-text))">
          <div class="con-title-after">
            <span
              :style="styleAfter"
              class="after"
            />
            <h3 class="text-xl">
              {{ title }}
            </h3>
          </div>
          <vs-icon
            v-if="type == 'alert' && closeable"
            :icon="closeIcon"
            icon-pack="symbolicon-font"
            class="vs-dialog-cancel vs-dialog-cancel--icon notranslate"
            @click="close"
          />
        </header>

        <div class="vs-dialog-text">
          <slot>{{ text }}</slot>
        </div>

        <footer v-if="buttonsHidden ? false : isPrompt || type == 'confirm'">
          <vs-button
            :disabled="isValid == 'none' ? false : !isValid"
            :color="color"
            :type="buttonAccept"
            @click="acceptDialog"
          >
            {{ acceptText }}
          </vs-button>
          <vs-button
            :text-color="cancelColor"
            :type="buttonCancel"
            :class="cancelClass"
            @click="cancelClose"
          >
            {{ cancelText }}
          </vs-button>
        </footer>

        <footer v-if="type == 'alert' && !isPrompt">
          <vs-button
            :color="color"
            :type="buttonAccept"
            @click="acceptDialog"
          >
            {{ acceptText }}
          </vs-button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import _color from '@/assets/utils/color.js'

export default {
  name: 'VsPrompt',
  props: {
    text: {
      default: null,
      type: String
    },
    type: {
      default: 'alert',
      type: String,
      validator: value => ['alert', 'confirm'].includes(value)
    },
    color: {
      default: 'primary',
      type: String
    },
    active: {
      default: false,
      type: Boolean
    },
    title: {
      default: 'Dialog',
      type: String
    },
    buttonAccept: {
      default: 'filled',
      type: String
    },
    buttonCancel: {
      default: 'flat',
      type: String
    },
    isValid: {
      default: 'none',
      type: [Boolean, String]
    },
    buttonsHidden: {
      default: false,
      type: Boolean
    },
    acceptText: {
      default: 'Accept',
      type: String
    },
    cancelText: {
      default: 'Cancel',
      type: String
    },
    closeIcon: {
      default: 'icon-close',
      type: String
    },
    closeOnBlur: {
      default: true,
      type: Boolean
    },
    closeable: {
      default: true,
      type: Boolean
    },
    accept: {
      default: null,
      type: Function
    },
    cancel: {
      default: null,
      type: Function
    },
    cancelColor: {
      type: String,
      default: '#B02210',
    },
    cancelClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:active', 'vsAccept', 'vsClose', 'vsCancel'],
  data: () => ({
    isPrompt: true,
    isActive: false,
  }),
  computed: {
    styleAfter() {
      return { background: _color.getColor(this.color, 1)}
    }
  },
  watch: {
    isActive() {
      this.$nextTick(() => {
        if (this.isActive) {
          this.insertBody()
        }
      })
    }
  },
  mounted() {
    if (this.active) {
      this.insertBody()
    }
    this.isActive = this.active
  },
  methods: {
    acceptDialog() {
      if (!this.isPrompt) {
        if (this.accept) this.accept()
        this.isActive = false
        this.$emit('update:active', false)
        this.$emit('vsAccept')
      } else {
        if (this.isValid || this.isValid == 'none') {
          if (this.accept) this.accept()
          this.isActive = false
          this.$emit('update:active', false)
          this.$emit('vsAccept')
        } else {
          this.rebound()
        }
      }
    },
    rebound() {
      this.$refs.dialog.classList.add('locked')
      setTimeout(() => {
        this.$refs.dialog.classList.remove('locked')
      }, 200)
    },
    close(event, container) {
      if (container) {
        if (
          event.target.className.indexOf('vs-dialog-dark') != -1 &&
          this.type == 'alert'
        ) {
          this.isActive = false
          this.$emit('update:active', false)
        } else if (event.target.className.indexOf('vs-dialog-dark') != -1) {
          this.rebound()
        }
      } else {
        if (
          event
            ? event.target.className.indexOf('vs-dialog-cancel') != -1
            : event
            ? event.target.className.indexOf('vs-dialog-cancel--icon') != -1
            : false
        ) {
          this.isActive = false
          this.$emit('update:active', false)
        }
      }
      this.$emit('vsClose')
    },
    cancelClose() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('vsCancel')
      if (this.cancel) this.cancel()
    },
    insertBody() {
      let elx = this.$refs.container
      if (elx) {
        document.body.insertBefore(elx, document.body.firstChild)
      }
    },
  }
}
</script>
