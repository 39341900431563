import { VARIABLES_DATA_TYPES } from '@/constants/constants'

const getters = {
  enabledApps: state => {
    return state.enabledApps
  },
  apiCalls: state => {
    return state.apiCalls
  },
  topics: state => {
    return state.topics
  },
  articles: state => {
    return state.articles
  },
  topicLength: state => {
    return state.topicLength
  },
  articleLength: state => {
    return state.articleLength
  },
  botPlain: state => state.botPlain,
  entities: state => {
    return state.entities
  },
  tags: state => {
    return state.tags
  },
  schedules: state => {
    return state.schedules
  },
  variables: state => {
    return state.variables?.filter(({ dataType }) => dataType !== VARIABLES_DATA_TYPES.SECRET)
  },
  variablesAll: state => {
    return state.variables
  },
  services: state => {
    return state.services
  },
  getTagsValues: state => tagId => {
    const tag = state.tags.find(s => s.id === tagId)
    return tag.values.map(s => s.value)
  },
  getSchedulesValues: state => scheduleId => {
    const schedule = state.schedules.find(s => s.id === scheduleId)
    return schedule.values.map(s => s.value)
  },
  getVariablesValues: state => variableId => {
    const variable = state.variables.find(v => v.id === variableId)
    return variable.values.map(v => v.value)
  },
  bot: state => {
    return state.bot
  },
  botId: state => {
    return state.botId
  },
  intentions: state => {
    return state.intentions
  },
  activeVersionData: state => {
    if (state.bot?.activeVersion) {
      return {
        versionId: state.bot.activeVersion,
        versionFork: state.bot.fork
      }
    } else {
      return null
    }
  },
  activeVersion: state => {
    return state.bot?.activeVersion
  },
  currentVersion: state => {
    return state.currentVersion
  },
  botActiveVersion: state => {
    return state.botVersions.find(a => a._id == state.bot.activeVersion)
  },
  botVersions: state => {
    return state.botVersions.sort((a, b) => b.active - a.active)
  },
}

export default getters
