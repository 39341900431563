import Request from './request'
import state from '../store/state'

const PATHS = {
  GET_ALL: '/user-feedback',
  GET_ALL_V2: '/user-feedback/v2'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = async (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
    params: {
      ...queryParams,
      populate: 'rootDialog'
    }
  }
  return Request.get(`${state.config.konecta}${PATHS.GET_ALL}`, config)
}

const getAllV2 = async (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: {
      ...queryParams,
    }
  }
  return Request.get(`${state.config.konecta}${PATHS.GET_ALL_V2}`, config)
}

export default {
  getAll,
  getAllV2
}
