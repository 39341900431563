const getters = {
  // Bots views
  getBots: state => {
    return state.bots
  },
  filters: state => {
    return state.filters
  },

  // Bot create or edit view
  bot: state => {
    return state.bot
  },

  // Bot validations
  validations: state => {
    return state.validations
  },

  // Select users
  isSelectUsersPromptOpened: state => {
    return state.isSelectUsersPromptOpened
  },
  isSelectQueuePromptOpened: state => {
    return state.isSelectQueuePromptOpened
  },
  queues: state => {
    return state.queues
  },
  users: state => {
    return state.users
  },
  usersSelected: state => {
    return state.usersSelected
  },

  // Select version intentions
  sectionReady: state => {
    return state.sectionReady
  },
  usersByChannelMap: state => {
    return state.usersByChannelMap
  },
  botTimeoutMessageValidation: state => {
    return state.botTimeoutMessageValidation
  },
  hasTimeoutValidation: state => {
    return state.hasTimeoutValidation
  },
  isWidgetSettingsOpen: state => {
    return state.isWidgetSettingsOpen
  },
  isTopicDetailOpen: state => {
    return state.isTopicDetailOpen
  },
}

export default getters
