const actions = {
  generateScreenPermissions({ commit }, sidebarItems) {
    const extractPermissions = (items, parentRoles = []) => {
      return items.reduce((acc, item) => {
        const currentRoles = item.roles || parentRoles;
        if (item.url) {
          const baseRoute = `/${item.url.split('/')[1]}`;
          acc[baseRoute] = currentRoles;
        }
        if (item.submenu) {
          Object.assign(acc, extractPermissions(item.submenu, currentRoles));
        }
        return acc;
      }, {});
    };

    const permissions = extractPermissions(sidebarItems);
    commit('SET_SCREEN_PERMISSIONS', permissions);
  }
};

export default actions;
