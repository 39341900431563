export default {
  navBarTitle: {
    en: 'Predefined Answers',
    es: 'Respuestas predefinidas',
    pt: 'Respostas predefinidas'
  },
  breadcrumb: {
    answerBuilder: {
      en: 'Answer Builder',
      es: 'Constructor de Respuestas',
      pt: 'Construtor de Respostas',
    }
  },
  installed: {
    title: {
      es: 'Éxito!',
      en: 'Success!',
      pt: 'Sucesso!'
    },
    success: {
      es: 'La respuesta predefinida fue creada correctamente.',
      en: 'The predefined response was successfully created.',
      pt: 'A resposta predefinida foi criada com sucesso.',
    }
  },
  table: {
    search: {
      placeholder: {
        en: 'Search an answer',
        es: 'Buscar una respuesta',
        pt: 'Pesquisar uma resposta'
      }
    },
    head: {
      answerName: {
        es: 'Nombre de la respuesta',
        en: 'Answer name',
        pt: 'Nome da resposta'
      },
      description: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição',
      },
      source: {
        en: 'Source',
        es: 'Fuente',
        pt: 'Fonte'
      },
      status: {
        en: 'Status',
        es: 'Estado',
        pt: 'Status'
      },
    },
    row: {
      galileo_api: {
        en: 'Galileo Program API',
        es: 'API del Programa Galileo',
        pt: 'Galileo Program API'
      },
      welcome: {
        es: 'Bienvenido',
        en: 'Welcome',
        pt: 'Bem-vindo',
      },
      manualEntry: {
        es: 'Entrada manual',
        en: 'Manual Entry',
        pt: 'Entrada manual',
      },
      notBeEdited: {
        en: 'Can not be edited or deleted',
        es: 'No se puede editar ni borrar',
        pt: 'Não pode ser editado ou excluído',
      },
    }
  },
  notifications: {
    installed: {
      title: {
        es: 'Éxito!',
        en: 'Success!',
        pt: 'Sucesso!'
      },
      text: {
        es: 'Las respuestas predefinidas se han creado correctamente.',
        en: 'The predefined answers was created successfully.',
        pt: 'As respostas predefinidas foram criadas com sucesso.'
      }
    },
  },
  buttons: {
    import: {
      en: 'Import',
      es: 'Importar',
      pt: 'Import',
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    }
  }
}