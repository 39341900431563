const state = {
  enabledApps: [],
  apiCalls: [],
  topics: [],
  articles: [],
  topicLength: undefined,
  articleLength: undefined,
  entities: [],
  variables: [],
  schedules: [],
  tags:[],
  bot: undefined,
  botPlain: undefined,
  botId: undefined,
  // For storing all intentions
  intentions: [],  
  botVersions: [],
  activeVersion: undefined,
  currentVersion: undefined,
  fork: null,
}

export default state
