export default {
  teamMembers: {
    editorsWarning: {
      okGotIt: {
        es: 'Ok, lo tengo.',
        en: 'Ok, got it.',
        pt: 'Ok, entendi.',
      },
      amIDuplicate: {
        es: 'Solo un aviso, también está viendo esta respuesta en otro navegador o pestaña. Si guarda los cambios aquí, se perderán sus ediciones en el otro lugar.',
        en: 'Just a heads up, you are also looking at this answer in another browser or tab. If you save changes here, then your edits in the other place will be lost.',
        pt: 'Apenas um alerta, você também está vendo esta resposta em outro navegador ou guia. Se você salvar as alterações aqui, suas edições no outro local serão perdidas.',
      },
      justAHeadsUp: {
        es: 'Solo un aviso, ',
        en: 'Just a heads up, ',
        pt: 'Apenas um alerta, ',
      },
      and: {
        es: ' y ',
        en: ' and ',
        pt: ' e ',
      },
      isAlsoLookingAtThisAnswer: {
        es: ' también está mirando esta respuesta',
        en: ' is also looking at this answer',
        pt: ' também está olhando para esta resposta',
      },
      areAlsoLookingAtThisAnswer: {
        es: ' también están mirando esta respuesta',
        en: ' are also looking at this answer',
        pt: ' também estão olhando para esta resposta',
      },
      andYouToo: {
        es: ', y tú también pero en otro navegador o pestaña',
        en: ', and you too but in another browser or tab',
        pt: ', e você também, mas em outro navegador ou guia',
      },
      ifAnotherChatManagerSavesChanges: {
        es: 'Si otro administrador de chat guarda los cambios antes que usted, sus ediciones actuales se perderán.',
        en: 'If another chat manager saves changes before you do, your current edits will be lost.',
        pt: 'Se outro gerente de bate-papo salvar as alterações antes de você, suas edições atuais serão perdidas.',
      },
      someoneJustSavedSomeChangesText: {
        es: ' acaba de guardar algunos cambios en esta respuesta. Deberá continuar en esta nueva versión, se perderán todos los cambios que haya realizado hasta ahora.',
        en: ' just saved some changes to this answer. You will need to continue in this new version, any changes you made so far will be lost.',
        pt: ' acabou de salvar algumas alterações nesta resposta. Você precisará continuar nesta nova versão, todas as alterações feitas até agora serão perdidas.',
      },
      someoneJustSavedSomeChangesButton: {
        es: 'Ok, carga la nueva versión',
        en: 'Ok, load new version',
        pt: 'Ok, carregue a nova versão',
      },
      someoneJustSavedSomeChangesTitle: {
        es: 'Nueva versión de esta respuesta',
        en: 'New version of this answer',
        pt: 'Nova versão desta resposta',
      }
    }
  },
  tagsLimitText: {
    es: 'y ${count} más',
    en: 'and ${count} more',
    pt: 'e mais ${count}',
  },
  addNew: {
    es: 'Añadir nuevo bloque',
    en: 'Add new',
    pt: 'Adicionar nova seção',
  },
  noSelfRedicectError: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!',
    },
    text: {
      es: 'No es posible redirigir a la respuesta seleccionada.',
      en: 'Unable to redirect to selected answer.',
      pt: 'Não é possível redirecionar para a resposta selecionada.',
    },
  },
  editConditionName: {
    es: 'Editar nombre de condición',
    en: 'Edit condition name',
    pt: 'Editar nome da condição'
  },
  noName: {
    es: 'Sin nombre',
    en: 'No name',
    pt: 'Sem nome'
  },
  edit: {
    es: 'Editar',
    en: 'Edit',
    pt: 'Editar'
  },
  preview: {
    es: 'Previsualización',
    en: 'Preview',
    pt: 'Visualizar'
  },
  selectAnswerPlaceholder: {
    es: 'Seleccionar una respuesta',
    en: 'Select an answer',
    pt: 'Selecione uma resposta'
  },
  selectTagPlaceholder: {
    es: 'Seleccionar una etiqueta',
    en: 'Select a tag',
    pt: 'Selecione uma etiqueta'
  },
  answerNotFound: {
    es: 'Respuesta no encontrada',
    en: 'Answer not found',
    pt: 'Resposta não encontrada'
  },
  noMatchesFound: {
    es: 'No se encontraron coincidencias',
    en: 'No matches found',
    pt: 'Nenhuma equivalência encontrada',
  },
  readOnlyPreview: {
    es: 'Vista previa de sólo lectura',
    en: 'Read-only preview',
    pt: 'Visualização somente leitura'
  },
  whenError: {
    es: 'Cuando hay error, salta a',
    en: 'When error, redirect to',
    pt: 'Quando erro, redirecione para'
  },
  showPreview: {
    es: 'Mostrar vista previa',
    en: 'Show preview',
    pt: 'Mostrar visualização'
  },
  hidePreview: {
    es: 'Ocultar vista previa',
    en: 'Hide preview',
    pt: 'Ocultar visualização'
  },
  duplicate: {
    es: 'Duplicar',
    en: 'Duplicate',
    pt: 'Duplicar'
  },
  moveAbove: {
    es: 'Mover arriba',
    en: 'Move above',
    pt: 'Mover acima'
  },
  moveBelow: {
    es: 'Mover abajo',
    en: 'Move below',
    pt: 'Mover para baixo'
  },
  save: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar'
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  new: {
    es: 'Nuevo',
    en: 'New',
    pt: 'Novo'
  },
  templateCondition: {
    title: {
      es: 'Importar condición',
      en: 'Import condition',
      pt: 'Importar condição'
    },
  },
  condition: {
    options: {
      edit: {
        es: 'Editar nombre',
        en: 'Edit name',
        pt: 'Editar nome'
      },
      save: {
        es: 'Guardar como plantilla',
        en: 'Save as template',
        pt: 'Salvar como modelo'
      },
      delete: {
        es: 'Eliminar condición',
        en: 'Delete condition',
        pt: 'Excluir condição'
      },
    },
    title: {
      es: 'Condición (IF)',
      en: 'Condition (IF)',
      pt: 'Condição (IF)'
    },
    if: {
      title: {
        es: 'Condición (IF)',
        en: 'Condition (IF)',
        pt: 'Condição (IF)'
      }
    },
    elseIf: {
      title: {
        es: 'Condición (ELSE IF)',
        en: 'Condition (ELSE IF)',
        pt: 'Condição (ELSE IF)'
      }
    },
    importTemplateModal: {
      noConditionSelected: {
        title: {
          es: 'No se ha seleccionado ninguna condición',
          en: 'No condition selected',
          pt: 'Nenhuma condição selecionada'
        },
        text: {
          es: 'Por favor, seleccione una condición para importar',
          en: 'Please, select a condition to import',
          pt: 'Por favor, selecione uma condição para importar'
        }
      },
      title: {
        es: 'Importar condición desde catálogo',
        en: 'Import condition from catalog',
        pt: 'Importar condição do catálogo'
      },
      searchFieldLabel: {
        es: 'Buscar y selecciona una condición IF',
        en: 'Search and select an IF condition',
        pt: 'Buscar e selecionar uma condição IF'
      },
      searchFieldPlaceholder: {
        es: 'Escribe el nombre de la condición',
        en: 'Write the condition name',
        pt: 'Escreva o nome da condição'
      },
      importAsFieldLabel: {
        es: 'Importar como',
        en: 'Import as',
        pt: 'Importar como'
      },
      importAsFieldValues: {
        copy: {
          es: 'Copia',
          en: 'Copy',
          pt: 'Cópia'
        },
        copy_message: {
          en: 'This creates a separate copy of the condition. You can edit it but any changes made on the original condition will not be reflected.',
          es: 'Esto crea una copia separada de la condición. Puedes editarla pero los cambios realizados en la condición original no se reflejarán.',
          pt: 'Isso cria uma cópia separada da condição. Você pode editá-la, mas quaisquer alterações feitas na condição original não serão refletidas.'
        },
        reference: {
          es: 'Referencia',
          en: 'Reference',
          pt: 'Referência'
        },
        reference_message: {
          en: 'This creates a reference to a condition in the catalogue. You can not edit it and any changes to this condition in the catalogue will be reflected here.',
          es: 'Esto crea una referencia a una condición en el catálogo. No puedes editarla y cualquier cambio a esta condición en el catálogo se reflejará aquí.',
          pt: 'Isso cria uma referência a uma condição no catálogo. Você não pode editá-la e quaisquer alterações a essa condição no catálogo serão refletidas aqui.'
        }
      },
      buttons: {
        import: {
          es: 'Importar',
          en: 'Import',
          pt: 'Importar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        }
      },
      errorTitle: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro'
      },
      errorText: {
        es: 'Hubo un error al importar la condición',
        en: 'There was an error importing the condition',
        pt: 'Houve um erro ao importar a condição'
      }
    },
    exportTemplateModal: {
      title: {
        es: 'Guardar IF como plantilla en el catálogo',
        en: 'Save IF as a template to the catalog',
        pt: 'Salvar IF como um modelo para o catálogo'
      },
      description: {
        es: 'Sólo la condición IF se guardará como plantilla, no el siguiente flujo conversacional.',
        en: 'Only the IF condition will be saved as a template, not the following conversational flow.',
        pt: 'Somente a condição IF será salva como um modelo, não o seguinte fluxo de conversação.'
      },
      hint_name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      hint_description: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição'
      },
      buttons: {
        create: {
          es: 'Guardar como plantilla',
          en: 'Save as template',
          pt: 'Salvar como modelo'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        }
      },
      successTitle: {
        es: 'Exito',
        en: 'Success',
        pt: 'Sucesso'
      },
      successText: {
        es: 'La condición se guardó exitosamente en el catálogo',
        en: 'The condition was successfully saved to the catalog',
        pt: 'A condição foi salva com sucesso no catálogo'
      },
      errorTitle: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro'
      },
      error_name: {
        es: 'El nombre es requerido',
        en: 'Name is required',
        pt: 'O nome é obrigatório'
      },
      error_description: {
        es: 'La descripción es requerida',
        en: 'Description is required',
        pt: 'A descrição é obrigatória'
      },
      errorText: {
        es: 'Hubo un error al guardar la condición en el catálogo',
        en: 'There was an error saving the condition to the catalog',
        pt: 'Houve um erro ao salvar a condição no catálogo'
      }
    },
  },
  response: {
    title: {
      es: 'Respuesta',
      en: 'Response',
      pt: 'Resposta'
    },
    tooltip: {
      es: 'Editar respuesta',
      en: 'Edit response',
      pt: 'Editar resposta'
    },
    tooltipOptionsList: {
      es: 'Editar lista de opciones',
      en: 'Edit options list',
      pt: 'Editar lista de opções'
    },
    textLabel: {
      es: 'Texto de respuesta',
      en: 'Response text',
      pt: 'Texto de resposta',
    },
    promptLabel: {
      es: 'Pregunta',
      en: 'Prompt',
      pt: 'Prompt',
    },
    mediaLabel: {
      es: 'Respuesta multimedia',
      en: 'Media response',
      pt: 'Resposta da mídia',
    },
    empty: {
      es: 'Este bloque no contiene ninguna respuesta',
      en: 'This block does not contain any responses',
      pt: 'Este bloco não contém nenhuma resposta'
    },
    buttonsTextLabel: {
      es: 'Texto de respuesta',
      en: 'Response text',
      pt: 'Texto de resposta',
    },
    addButton: {
      es: 'Agragar opción',
      en: 'Add option',
      pt: 'Adicionar opção',
    },
    buttonsOptionsLabel: {
      es: 'Opciones de respuesta',
      en: 'Response options',
      pt: 'Opções de resposta',
    },
    buttonPayloadPlaceholder: {
      es: 'Escriba un texto o presione @ para agregar variables',
      en: 'Write some text or press @ to add variables',
      pt: 'Escreva algum texto ou pressione @ para adicionar variáveis',
    },
    buttonCaptionPlaceholder: {
      es: 'Escriba un texto o presione @ para agregar variables',
      en: 'Write some text or press @ to add variables',
      pt: 'Escreva algum texto ou pressione @ para adicionar variáveis',
    },
    buttonsSaveVariableLabel: {
      es: 'Guardar opción seleccionada en variable',
      en: 'Save selected option in variable',
      pt: 'Salvar opção selecionada na variável',
    },
    buttonMandatoryLabel : {
      es: 'Respuesta mandatoria',
      en: 'Mandatory answer',
      pt: 'Resposta obrigatória'
    },
    youtubeURLLabel: {
      es: 'URL del video',
      en: 'video URL',
      pt: 'URL del video'
    },
    youtubeSecondLabel: {
      es: 'Segundo en el que se iniciará el video',
      en: 'Second in which the video will start',
      pt: 'Segundo en el que se iniciará el video'
    },
    youtubePreviewButton: {
      es: 'Previsualizar',
      en: 'Preview',
      pt: 'Pré-visualizar'
    },
    youtubeURLPlaceholder: {
      es: 'https://www.youtube.com/watch?v=video',
      en: 'https://www.youtube.com/watch?v=video',
      pt: 'https://www.youtube.com/watch?v=video'
    },
    invalidLinkYoutube: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'El enlace es inválido',
        en: 'The link is invalid',
        pt: 'O link é inválido'
      }
    },
    linkURLLabel: {
      es: 'URL o número de teléfono',
      en: 'URL or phone number',
      pt: 'URL ou número de telefone',
    },
    linkURLPlaceholder: {
      es: 'https://app.konecta.global/#/ruta',
      en: 'https://app.konecta.global/#/route',
      pt: 'https://app.konecta.global/#/rota',
    },
    linkCaptionLabel: {
      es: 'Título',
      en: 'Caption',
      pt: 'Rubrica',
    },
    linkCaptionPlaceholder: {
      es: 'Click aquí!',
      en: 'Click here!',
      pt: 'Clique aqui!',
    },
    linkTargetLabel: {
      es: 'Abrir en',
      en: 'Target',
      pt: 'Abrir em',
    },
    linkTargetOptionLabel: {
      _blank: {
        es: 'Nueva pestaña',
        en: 'New tab',
        pt: 'Nova aba',
      },
      _top: {
        es: 'Pestaña actual',
        en: 'Current tab',
        pt: 'Aba atual',
      }
    },
    invalidLink: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El enlace es inválido',
        en: 'The link is invalid',
        pt: 'O link é inválido',
      },
    },
    captionUrlSliderLabel: {
      es: 'Usar una URL en el título diferente a la del video',
      en: 'Use an URL in the caption different from the one in the video',
      pt: 'Use uma URL no rubrica diferente da do vídeo',
    },
    captionUrlLabel: {
      es: 'URL del título',
      en: 'Caption URL',
      pt: 'URL da rubrica',
    },
    invalidCaptionUrl: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'La URL del título es inválida',
        en: 'The caption URL is invalid',
        pt: 'O URL da rubrica é inválido'
      }
    },
  },
  redirect: {
    title: {
      es: 'Redirigir',
      en: 'Redirect',
      pt: 'Redirecionar'
    },
    edit: {
      es: 'Editar redirección',
      en: 'Edit redirect',
      pt: 'Editar redirecionamento'
    },
    empty: {
      es: 'Este bloque no tiene una respuesta seleccionada',
      en: 'This block does not have a selected answer',
      pt: 'Esta seção não tem uma resposta selecionada'
    },
  },
  app: {
    notDefined: {
      es: 'No definido',
      en: 'Not defined',
      pt: 'Não definido'
    },
    offHourAnswer: {
      label: {
        en : 'Off hour answer',
        es : 'Respuesta fuera de horario',
        pt : 'Resposta fora do horário'
      },
    },
  },
  setVariable: {
    title: {
      es: 'Establecer variable',
      en: 'Set variables',
      pt: 'Definir variável'
    },
    edit: {
      es: 'Editar variables',
      en: 'Edit variables',
      pt: 'Editar variáveis'
    },
    empty: {
      es: 'Este bloque no tiene variables definidas',
      en: 'This block has no variables defined',
      pt: 'Esta seção não tem variáveis ​definidas'
    },
    add: {
      es: 'Agregar',
      en: 'Add',
      pt: 'Adicionar'
    },
    notFound: {
      es: 'Variable no encontrada',
      en: 'Variable not found',
      pt: 'Variável não encontrada'
    },
    system: {
      es: 'Variable de sistema',
      en: 'System variable',
      pt: 'Variável do sistema'
    },
    setValue: {
      es: 'Fijar valor',
      en: 'Set value',
      pt: 'Definir valor',
    },
    copyVariable: {
      es: 'Copiar variable',
      en: 'Copy variable',
      pt: 'Copiar variável',
    },
    cleanVariable: {
      es: 'Remover valor',
      en: 'Remove value',
      pt: 'Remover valor',
    },
    calculate: {
      es: 'Calcular',
      en: 'Calculate',
      pt: 'Calcule',
    },
  },
  optionsAnswer: {
    label: {
      name : {
        es: 'Escribe el nombre',
        en: 'Write the name',
        pt: 'Escreva o nome'
      }
    },
    title: {
      es: 'Respuestas relacionadas',
      en: 'Related answers',
      pt: 'Respostas relacionadas'
    },
    description: {
      es: 'Mostrar una lista de respuestas relacionadas al usuario',
      en: 'Display a list of related answers to the user.',
      pt: 'Exibir uma lista de respostas relacionadas ao usuário.',
    },
    addButton: {
      es: 'Añadir respuesta relacionada',
      en: 'Add related answer',
      pt: 'Adicionar resposta relacionada'
    },
    empty: {
      es: 'Este bloque no tiene respuestas relacionadas definidas',
      en: 'This block does not have related answers defined',
      pt: 'Este bloco não tem respostas relacionadas definidas'
    },
  },
  optionsDynamic: {
    selectionType: {
      popUpTitle: {
        es: 'Lista de opciones',
        en: 'List of options',
        pt: 'Lista de opções'
      },
      title: {
        es: 'Seleccione el tipo de lista',
        en: 'Select type of list',
        pt: 'Seleccione o tipo de lista'
      },
      fixedOptions: {
        title: {
          es: 'Opciones fijas',
          en: 'Fixed options',
          pt: 'Opções fixas'
        },
        description: {
          es: 'Define las opciones de respuesta',
          en: 'Define the answer options',
          pt: 'Definir as opções de resposta'
        },
        emptyReponse: {
          es: 'Este bloque no tiene texto',
          en: 'This block has no text',
          pt: 'Este bloco não tem texto'
        },
        emptyOptions: {
          es: 'Este bloque no tiene lista de opciones',
          en: 'This block has no list of options',
          pt: 'Este bloco não possui lista de opções'
        },
      },
      dynamicData: {
        title: {
          es: 'Datos dinámicos',
          en: 'Dynamic data',
          pt: 'Dados dinâmicos'
        },
        description: {
          es: 'Presentar datos dinámicos de la API como opciones de respuesta',
          en: 'Present dynamic data from API as answer options',
          pt: 'Apresentar dados dinâmicos da API como opções de resposta'
        }
      },
      add: {
        es: 'Añadir',
        en: 'Add',
        pt: 'Acrescentar'
      }
    },
    title: {
      es: 'Listas de opciones con datos dinámicos',
      en: 'Lists of options with dynamic data',
      pt: 'Listas de opções com dados dinâmicos'
    },
    messageAbove: {
      checkBoxTitle: {
        es: 'Incluir un mensaje encima de la lista de opciones',
        en: 'Include a message above the list of options',
        pt: 'Incluir uma mensagem acima da lista de opções'
      },
      title: {
        es: 'Mensaje sobre la lista de opciones',
        en: 'Message above the list of options',
        pt: 'Mensagem acima da lista de opções'
      },
      message: {
        es: 'Mensaje',
        en: 'Message',
        pt: 'Mensagem'
      },
      messagePlaceholder: {
        es: 'Introducir mensaje',
        en: 'Enter message',
        pt: 'Digite a mensagem'
      },
    },
    defineData: {
      templateExample: {
        popUpTitle: {
          es: 'Ejemplo de plantilla',
          en: 'Template example',
          pt: 'Exemplo de modelo'
        },
        button: {
          es: 'Ejemplo de plantilla',
          en: 'Template example',
          pt: 'Exemplo de modelo'
        },
        example: {
          es: 'Ejemplo',
          en: 'Example',
          pt: 'Exemplo'
        },
        template: {
          es: 'Plantilla',
          en: 'Template',
          pt: 'Modelo'
        }
      },
      dataPointsOptions: {
        1: {
          es: '1 punto de dato',
          en: '1 data point',
          pt: '1 ponto de dados'
        },
        2: {
          es: '2 puntos de datos',
          en: '2 data points',
          pt: '2 pontos de dados'
        },
        3: {
          es: '3 puntos de datos',
          en: '3 data points',
          pt: '3 pontos de dados'
        }
      },
      title: {
        es: 'Definir datos',
        en: 'Define Data',
        pt: 'Definir Dados'
      },
      template: {
        es: 'Plantilla',
        en: 'Template',
        pt: 'Modelo'
      },
      listTypeVariable: {
        es: 'Variable de tipo lista',
        en: 'List type variable',
        pt: 'Variável do tipo de lista'
      },
      numberOptionsDisplay: {
        es: 'Visualización numérica de las opciones',
        en: 'Number display of options',
        pt: 'Exibição do número de opções'
      }
    },
    mapData: {
      dataPointTypeOptions: {
        number: {
          es: 'Número',
          en: 'Number',
          pt: 'Número'
        },
        date: {
          es: 'Fecha',
          en: 'Date',
          pt: 'Data'
        },
        text: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        }
      },
      title: {
        es: 'Mapeo de datos',
        en: 'Map data',
        pt: 'Dados do mapa'
      },
      dataPoints: {
        dataPoint1: {
          es: 'Punto de dato 1',
          en: 'Data point 1',
          pt: 'Ponto de dados 1'
        },
        dataPoint2: {
          es: 'Punto de dato 2',
          en: 'Data point 2',
          pt: 'Ponto de dados 2'
        },
        dataPoint3: {
          es: 'Punto de dato 3',
          en: 'Data point 3',
          pt: 'Ponto de dados 3'
        },
        key: {
          es: 'Llave',
          en: 'Key',
          pt: 'Chave'
        },
        keyPlaceholder: {
          es: 'Introducir clave',
          en: 'Enter key',
          pt: 'Tecla Enter'
        },
        type: {
          es: 'Tipo',
          en: 'Type',
          pt: 'Tipo'
        },
        typePlaceholder: {
          es: 'Selecione el tipo de dato',
          en: 'Select type of data',
          pt: 'Selecione o tipo de dados'
        },
        format:{
          es: 'Formato',
          en: 'Format',
          pt: 'Formato'
        },
      },
      selectAnswer: {
        es: 'Formato de mensaje de respuesta seleccionado',
        en: 'Selected answer message format',
        pt: 'Formato de mensagem de resposta selecionado'
      },
      inputMessage: {
        en: 'Ingrese mensaje',
        es: 'Enter Message',
        pt: 'Digite a mensagem'
      },
      inputTooltip: {
        es: 'Escriba el mensaje que desea mostrar para la respuesta seleccionada. Puede ingresar texto regular y agregar claves para insertar datos dinámicos en el mensaje. Por ejemplo: Transacción de {{cantidad}} fue realizada en {{fecha}}',
        en: 'Write the message you want to show for the selected answer. You can enter regular text and add keys to insert dynamic data into the messge. For example: Transaction of {{amount}} on {{date}}',
        pt: 'Escreva a mensagem que deseja mostrar para a resposta selecionada. Você pode inserir texto normal e adicionar chaves para inserir dados dinâmicos na mensagem. Por exemplo: Transação de {{valor}} na {{data}}'
      }
    },
    saveAsVariable: {
      title: {
        es: 'Guardar una clave de datos como variable',
        en: 'Save a data key as variable',
        pt: 'Salvar uma chave de dados como variável'
      },
      titleView: {
        es: 'Guardó una clave de datos como variable',
        en: 'Saved a data key as variable',
        pt: 'Salvou uma chave de dados como variável'
      },
      key: {
        es: 'Llave',
        en: 'Key',
        pt: 'Chave'
      },
      keyPlaceholder: {
        es: 'Introducir clave',
        en: 'Enter key',
        pt: 'Tecla Enter'
      },
      variable: {
        es: 'Variable',
        en: 'Variable',
        pt: 'Variable',
      },
    }
  },
  options: {
    title: {
      es: 'Lista de opciones',
      en: 'Options list',
      pt: 'Lista de opções'
    },
    description: {
      es: 'Mostrar una lista de opciones al usuario',
      en: 'Show a list of options to the user',
      pt: 'Mostrar uma lista de opções para o usuário',
    },
    // variables:{
    //   label:{
    //     es: 'Guardar respuesta en',
    //     en: 'Save response in',
    //     pt: 'Salvar resposta em',
    //   }
    // },
    empty: {
      es: 'Este bloque no tiene opciones definida',
      en: 'This block does not have options defined',
      pt: 'Este bloco não tem opções definidas'
    },
  },
  handOff: {
    title: {
      es: 'Transferencia',
      en: 'Hand Off',
      pt: 'Transferência'
    },
    empty: {
      es: 'Este bloque no tiene una cola definida. El cliente será dirigido a la cola por defecto',
      en: 'This block does not have a hand off defined. The customer will be directed to the default queue',
      pt: 'Este bloco não tem uma transferência definida. O cliente será direcionado para a fila padrão'
    },
    edit: {
      es: 'Editar transferencia',
      en: 'Edit hand off',
      pt: 'Editar transferência'
    },
    placeholder: {
      es: 'Seleccionar una cola',
      en: 'Select a queue',
      pt: 'Selecione uma fila'
    },
    queueLabel: {
      es: 'Cola de destino',
      en: 'Target queue',
      pt: 'Fila de destino'
    },
    showAfterHoursMessageLabel: {
      es: 'Mostrar mensaje fuera del horario comercial',
      en: 'Show outside of business hours message',
      pt: 'Mostrar mensagem fora do horário comercial'
    },
    showAfterHoursMessageTooltip: {
      es: 'Si está activado y el usuario se encuentre fuera del horario de atención, entonces se enviará la respuesta definida en la configuración general del bot, para cuando el usuario se encuentra fuera del horario de atención.',
      en: 'If enabled and the user is out of office hours, then the response defined in the general configuration of the bot, for when the user is out of office hours, will be sent.',
      pt: 'Se estiver ativado e o usuário estiver fora do horário comercial, então será enviada a resposta definida na configuração geral do bot, para quando o usuário estiver fora do horário comercial..'
    },
    showHandOffMessageLabel: {
      es: 'Mostrar mensaje de transferencia',
      en: 'Show hand off message',
      pt: 'Mostrar mensagem de transferência'
    },
    showHandOffMessageTooltip: {
      es: 'Si está activado y el usuario se encuentre dentro del horario de atención, entonces se enviará la respuesta definida en la configuración general del bot, para cuando el usuario es redirigido a la cola de pedidos de atención.',
      en: "If enabled and the user is within office hours, then the response defined in the general configuration of the bot, for when the user is redirected to the service request queue, will be sent.",
      pt: 'Se estiver ativado e o usuário estiver dentro do horário de expediente, então será enviada a resposta definida na configuração geral do bot, para quando o usuário for redirecionado para a fila de solicitação de serviço.'
    },
  },
  capture: {
    title: {
      es: 'Capturar entrada',
      en: 'Capture input',
      pt: 'Capturar entrada'
    },
    description: {
      es: 'Esperar a que el usuario escriba',
      en: 'Wait for the user to type',
      pt: 'Aguarde o usuário digitar',
    },
    variables:{
      label:{
        es: 'Guardar respuesta en',
        en: 'Save response in',
        pt: 'Salvar resposta em',
      }
    },
    empty: {
      es: 'Este bloque no tiene un captura definida',
      en: 'This block does not have a capture defined',
      pt: 'Este bloco não tem uma capturar definida'
    },
  },
  apiCall: {
    title: {
      es: 'Integración',
      en: 'API Call',
      pt: 'Integração'
    },
    selectPlaceholder: {
      es: 'Seleccionar integración',
      en: 'Select API call',
      pt: 'Selecione integração'
    },
    edit: {
      es: 'Editar integración',
      en: 'Edit API call',
      pt: 'Editar integração'
    },
    app: {
      es: 'Integración de aplicaciones',
      en: 'App integrations',
      pt: 'Integrações de aplicativos'
    },
    empty: {
      es: 'Esta integración está vacía',
      en: 'This API call is empty',
      pt: 'Esta integração está vazia'
    },
    showVariablesIntegration: {
      es: 'Variables definidas en esta integración',
      en: 'Variables set in this integration',
      pt: 'Variáveis definidas nesta integração'
    },
    emptyVariablesIntegration: {
      es: 'No hay variables definidas',
      en: 'No variables defined',
      pt: 'Não há variáveis definidas'
    },
    redirectSameAnswerTitle: {
      es: "El gestor de errores de esta API redirige a la misma respuesta de la que procede.",
      en: "This API's error handler redirects to the same answer it came from.",
      pt: "O manipulador de erros dessa API redireciona para a mesma resposta de onde veio."
    },
    redirectSameAnswerBody: {
      es: 'Por favor, revise los parámetros de la solicitud para evitar ser redirigido a esta respuesta.',
      en: 'Please review the request parameters to avoid being looped back to this answer.',
      pt: 'Revise os parâmetros da solicitação para evitar o retorno a esta resposta.'
    },
    url: {
      es: 'URL',
      en: 'URL',
      pt: 'URL',
    }
  },
  remove: {
    dialog: {
      title: {
        es: 'Confirmación',
        en: 'Confirmation',
        pt: 'Confirmação',
      },
      text: {
        es: '¿Estás seguro de querer eliminar esta sección?',
        en: 'Are you sure you want to delete the section?',
        pt: 'Tem certeza que quer apagar esta seção?',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  related:{
    nameDisplay:{
      es: 'Nombre para mostrar',
      en: 'Name to display',
      pt: 'Nome para exibir',
    },
    answer:{
      es: 'Respuesta',
      en: 'Answer',
      pt: 'Responder',
    }
  }
}
