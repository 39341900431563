export default {
  audit: {
    title: {
      es: 'Auditoría',
      en: 'Audit',
      pt: 'Auditoria'
    }
  },
  answerHistory: {
    title: {
      es: 'Historial de respuestas',
      en: 'Answer History',
      pt: 'Historial de respuestas'
    }
  },
  training: {
    title: {
      es: 'Mejoras',
      en: 'Improve',
      pt: 'Melhoria'
    },
    submenu: {
      clustering: {
        title: {
          es: 'Clusterización',
          en: 'Clustering',
          pt: 'Agrupamento'
        }
      },
      feedback: {
        title: {
          es: 'Información',
          en: 'Feedback',
          pt: 'Retorno'
        }
      }
    },
    createBot: {
      es: 'Crear Bot',
      en: 'Create Bot',
      pt: 'Criar Bot'
    },
    dialogView: {
      es: 'Vista de Diálogo',
      en: 'Dialogue View',
      pt: 'Visão de Diálogo'
    },
    flowsDialog: {
      es: 'Vista de Flujos',
      en: 'Flows View',
      pt: 'Visão de fluxos'
    },
    languages: {
      es: 'Lenguajes',
      en: 'Languages',
      pt: 'Idiomas'
    },
    channels: {
      es: 'Canales',
      en: 'Channels',
      pt: 'Canales'
    },
    versions: {
      es: 'Versiones',
      en: 'Versions',
      pt: 'Versões'
    },
    intentions: {
      es: 'Intenciones',
      en: 'Intentions',
      pt: 'Intenções'
    },
    entities: {
      es: 'Entitidades',
      en: 'Entities',
      pt: 'Entidades'
    },
    integrations: {
      es: 'Integraciones',
      en: 'Integrations',
      pt: 'Integrações'
    }
  },
  analysis: {
    dashboards: {
      title: {
        es: 'Dashboards',
        en: 'Dashboards',
        pt: 'Painels'
      },
    },
    title: {
      es: 'Análisis',
      en: 'Analysis',
      pt: 'Análise'
    },
    queryBuilder: {
      es: 'Generador de métricas',
      en: 'Metric Builder',
      pt: 'Construtor de Métricas'
    },
    answersAnalytics: {
      es: 'Todas las métricas',
      en: 'All metrics',
      pt: 'Todas as métricas'
    },
    answersMetricAnalytics: {
      es: 'Respuestas',
      en: 'Answers',
      pt: 'Respostas'
    },
    proactiveDashboard: {
      es: 'Proactivo',
      en: 'Proactive',
      pt: 'Proativo'
    },
    conversationDashboard: {
      es: 'Conversación',
      en: 'Conversation',
      pt: 'Conversação'
    },
  },
  security: {
    title: {
      es: 'Seguridad',
      en: 'Security',
      pt: 'Segurança'
    },
    ipRanges: {
      es: 'Rangos de IP',
      en: 'IP Ranges',
      pt: 'IP ranges'
    }
  },
  settings: {
    title: {
      es: 'Configuración',
      en: 'Settings',
      pt: 'Configuração'
    },
    users: {
      es: 'Usuarios',
      en: 'Users',
      pt: 'Usuários'
    },
    themes: {
      es: 'Temas',
      en: 'Themes',
      pt: 'Temas'
    },
    nlu: {
      es: 'NLU',
      en: 'NLU',
      pt: 'NLU'
    },
    sso: {
      es: 'SSO',
      en: 'SSO',
      pt: 'SSO'
    }
  },
  bots: {
    title: {
      es: 'Bots',
      en: 'Bots',
      pt: 'Bots'
    }
  },
  contact: {
    title: {
      es: 'Contacto',
      en: 'Contacts',
      pt: 'Contatos'
    },
    attentionRequests: {
      es: 'Pedidos de atención',
      en: 'Pending Chats',
      pt: 'Pedidos de atendimento'
    },
    allChats: {
      es: 'Todos los chats',
      en: 'All Chats',
      pt: 'Todos os chats'
    },
    allChatsV2: {
      es: 'Todos los chats V2',
      en: 'All Chats V2',
      pt: 'Todos os chats V2'
    },
    queues: {
      es: 'Colas de atención',
      en: 'Attentions Queues',
      pt: 'Colas de atencion'
    },
    attentionRequestsSettings: {
      es: 'Configuración',
      en: 'Settings',
      pt: 'Configuração'
    }
  },
  company: {
    title: {
      es: 'Organizaciones',
      en: 'Tenants',
      pt: 'Organizações'
    }
  },
  userStatus: {
    set: {
      es: 'Cambiar',
      en: 'Change',
      pt: 'Mudar'
    },
    youAre: {
      es: 'Estás ',
      en: "You're set to ",
      pt: 'Você está '
    },
    offline: {
      es: 'ausente',
      en: 'away',
      pt: 'ausente'
    },
    online: {
      es: 'disponible',
      en: 'online',
      pt: 'disponivel'
    }
  },
  logout: {
    title: {
      es: 'Cerrar sesión',
      en: 'Logout',
      pt: 'Sair'
    },
    confirm: {
      title: {
        es: 'Atención',
        en: 'Warning',
        pt: 'Aviso'
      },
      text: {
        es: '¿Está seguro de cerrar sesión?',
        en: 'Are you sure to logout?',
        pt: 'Tem certeza de que deseja sair?'
      },
      accept: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    }
  }
}
